import axios from 'p/axios_cl';
import qs from 'qs';

const config = {
  baseURL: window.gissIp,
  paramsSerializer: {
    serialize(params) {
      // get请求传递数组
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  }
};

// 我的服务
const serviceMy = params => axios.get('/map/service/my', { params, ...config });

// 从图层发布服务
const servicePublish = params =>
  axios.post('/map/service/publish/layer', qs.stringify(params), { ...config });

// 删除服务
const serviceDel = params =>
  axios.post('/map/service/del', qs.stringify(params), { ...config });

// 我的服务
const serviceChangeEnableState = params =>
  axios.post('/map/service/changeEnableState', qs.stringify(params), {
    ...config
  });

export default {
  serviceChangeEnableState,
  serviceDel,
  servicePublish,
  serviceMy
};
