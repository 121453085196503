// 登录、认证、充值
const state = {
  // 认证选择窗口
  selectiveAuthenticateDialogVisible: false,
  // 个人认证窗口
  personalCertificateDialogVisible: false,
  // 企业认证窗口
  enterpriseCertificationDialogVisible: false,
  // 用户登录窗口
  loginDialogVisible: false,
  // 用户状态
  loginData: localStorage.userName,
  // 站内信窗口
  messageDetailId: {
    status: false
  },
  // 故障提交窗口
  commitFailuresDialogVisible: false,
  // 产品建议窗口
  productSuggestionsDialogVisible: false
};

const mutations = {
  setProductSuggestionsDialogVisible(state, val) {
    state.productSuggestionsDialogVisible = val;
  },
  setCommitFailuresDialogVisible(state, val) {
    state.commitFailuresDialogVisible = val;
  },
  setSelectiveAuthenticateDialogVisible(state, val) {
    state.selectiveAuthenticateDialogVisible = val;
  },
  setPersonalCertificateDialogVisible(state, val) {
    state.personalCertificateDialogVisible = val;
  },
  setEnterpriseCertificationDialogVisible(state, val) {
    state.enterpriseCertificationDialogVisible = val;
  },
  setLoginDialogVisible(state, val) {
    state.loginDialogVisible = val;
  },
  setLoginData(state, val) {
    state.loginData = val;
  },
  setMessageDetailId(state, val) {
    state.messageDetailId = val;
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
