<template>
  <el-dialog
    :visible.sync="loginDialogVisible"
    :close-on-click-modal="false"
    destroy-on-close
    width="25vw"
  >
    <div class="login">
      <!-- logo -->
      <div class="login-logo" />
      <div class="login-border" />
      <!-- 密码登录/短信登录切换 -->
      <div class="login-title">
        <div class="login-title-pass">
          <el-button
            type="text"
            :style="{
              color: [title ? '#0096FF' : ''],
              fontWeight: [title ? 'bold' : '']
            }"
            @click="loginMethod(true)"
            >密码登录</el-button
          >
          <div class="login-title-border" v-show="title" />
        </div>
        <div class="login-title-note">
          <el-button
            type="text"
            @click="loginMethod(false)"
            :style="{
              color: [!title ? '#0096FF' : ''],
              fontWeight: [!title ? 'bold' : '']
            }"
            >短信登录/注册</el-button
          >
          <div class="login-title-border" v-show="!title" />
        </div>
      </div>
      <el-form
        :model="passLogin"
        ref="passLoginRuleForm"
        :rules="userFormRules"
      >
        <!-- 密码登录输入框 -->
        <div class="login-input" v-show="title">
          <el-form-item prop="user" class="formItem">
            <el-input
              v-model="passLogin.user"
              placeholder="请输入账号"
              maxlength="11"
              oninput="value=value.replace(/[^\d]/g,'')"
              clearable
            >
              <i slot="prefix" class="icon login-user"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="passLogin.password"
              placeholder="请输入密码"
              oninput="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
              maxlength="20"
              show-password
              clearable
            >
              <i slot="prefix" class="icon login-pass"></i>
            </el-input>
          </el-form-item>
          <!-- 忘记密码、记住密码 -->
          <div class="login-password">
            <div>
              <el-checkbox v-model="isRemember" class="login-password-save"
                >记住密码</el-checkbox
              >
            </div>
            <el-dropdown
              trigger="click"
              class="login-password-forget"
              @command="loginMethod(false)"
            >
              <span>忘记密码？</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>通过短信快速登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- 密码登录按钮 -->
        <div class="login-button" v-show="title">
          <el-form-item>
            <el-button @click="passLoginClick('passLoginRuleForm')"
              >登录</el-button
            >
          </el-form-item>
        </div>
      </el-form>
      <el-form
        :model="noteLogin"
        ref="noteLoginClickRuleForm"
        :rules="userFormRules"
      >
        <!-- 短信登录输入框 -->
        <div class="login-input" v-show="!title">
          <el-button
            :disabled="timecode"
            @click="getCodeClick('noteLoginClickRuleForm')"
            type="text"
            class="login-input-fsdx"
            >{{ getTime
            }}<span v-if="getTime !== '获取验证码'">秒后重试</span></el-button
          >
          <div class="login-input-border" />
          <el-form-item prop="phone" class="nodeInput">
            <country-code-selector class="login-input-areaCode" />
            <el-input
              v-model="noteLogin.phone"
              @blur="maxLen = $event.target.value"
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="11"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code" class="nodeInput">
            <el-input
              v-model="noteLogin.code"
              oninput="value=value.replace(/[^\d]/g,'')"
              placeholder="请输入验证码"
              maxlength="6"
            >
              <i slot="prefix" class="icon login-verify"></i></el-input
          ></el-form-item>
        </div>
        <!-- 短信注册/登录按钮 -->
        <div class="login-button code" v-if="!title">
          <el-form-item>
            <el-button @click="noteLoginClick('noteLoginClickRuleForm')"
              >注册/登录</el-button
            >
          </el-form-item>
          <!-- 底部文字说明 -->
          <div class="login-explain">
            <img
              src="@/assets/login-explain.png"
              class="login-explain-img"
            /><span class="login-explain-text"
              >未注册的手机号我们将自动帮你注册账号</span
            >
          </div>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
// 国际区号组件
import countryCodeSelector from '../countryCodeSelector/components/index.vue';
// 表单验证规则
import { userRulesMixin } from '@/plugins/mixin';
export default {
  name: 'Login',
  mixins: [userRulesMixin],
  components: {
    countryCodeSelector
  },
  data() {
    return {
      title: false, //密码/短信登录切换
      getTime: '获取验证码', //获取验证码的内容显示
      timecode: false, //获取验证码按钮的禁用状态
      timer: null, //验证码倒计时
      isRemember: '', //记住密码
      areaCode: '', //国际区号
      // 密码登录表单数据
      passLogin: {
        user: '',
        password: ''
      },
      // 短信登录表单数据
      noteLogin: {
        phone: '13421532195',
        code: '123456',
        client: 'web'
      }
    };
  },
  created() {
    // 在页面加载的时候，查看cookie用户名和密码
    this.getCookie();
  },
  computed: {
    loginDialogVisible: {
      get() {
        return this.$store.state.userData.loginDialogVisible;
      },
      set(val) {
        this.$store.commit('userData/setLoginDialogVisible', val);
      }
    }
  },
  methods: {
    // 密码/短信登录切换
    loginMethod(val) {
      if (val) {
        this.title = true;
        this.$refs['passLoginRuleForm'].resetFields();
        this.getCookie();
      } else {
        this.title = false;
        this.$refs['noteLoginClickRuleForm'].resetFields();
      }
    },
    // 发送验证码&&倒计时
    getCodeClick(formName) {
      this.$refs[formName].validateField('phone', valid => {
        if (!valid) {
          var params = {
            phone: this.noteLogin.phone,
            captchaAngle: 1,
            captchaId: 1
          };
          this.$service.login.sendCode(params).then(data => {
            if (data.data.status === 200) {
              this.timecode = true;
              const TIME_COUNT = 59; //倒计时60秒
              if (!this.timer) {
                this.getTime = TIME_COUNT;
                this.timer = setInterval(() => {
                  if (this.getTime > 0 && this.getTime <= TIME_COUNT) {
                    this.getTime--;
                  } else {
                    this.timecode = false;
                    this.getTime = '获取验证码';
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
              this.$message.success('验证码已发送，请注意查收短信');
            }
          });
        }
      });
    },
    // 密码登录
    passLoginClick(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.isRemember) {
            this.setCookie(
              this.passLogin.user,
              this.passLogin.password,
              7 //保存七天
            );
          } else {
            this.clearCookie();
          }
          localStorage.userName = this.passLogin.user;
          this.$message.success('登录成功！');
          this.$store.commit('userData/setLoginDialogVisible', false);
          this.$store.commit('userData/setLoginData', localStorage.userName);
        } else {
          return false;
        }
      });
    },
    // 短信登录
    noteLoginClick(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = this.noteLogin;
          this.$service.login.login(params).then(data => {
            if (data.data.status === 200) {
              localStorage.token = data.data.data.token;
              localStorage.userName = this.noteLogin.phone;
              this.$message.success(data.data.msg);
              this.$store.commit('userData/setLoginDialogVisible', false);
              this.$store.commit(
                'userData/setLoginData',
                localStorage.userName
              );
            }
          });
        }
      });
    },
    // 设置cookie
    setCookie(username, password, exdays) {
      var exdate = new Date(); // 获取当前登录的时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 将当前登录的时间加上七天，就是cookie过期的时间，也就是保存的天数
      // 字符串拼接cookie,因为cookie存储的形式是name=value的形式
      window.document.cookie =
        'userName' + '=' + username + ';path=/;expires=' + exdate.toGMTString();
      window.document.cookie =
        'userPwd' + '=' + password + ';path=/;expires=' + exdate.toGMTString();
      window.document.cookie =
        'isRemember' +
        '=' +
        this.isRemember +
        ';path=/;expires=' +
        exdate.toGMTString();
    },
    // 清除cookie
    clearCookie() {
      this.setCookie('', '', -1); // 清空并设置天数为负1天
    },
    // 获取cookie的账号密码
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; ');
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('=');
          // 判断查找相对应的值
          if (arr2[0] === 'userName') {
            this.passLogin.user = arr2[1]; // 转存一份保存用户名和密码
          } else if (arr2[0] === 'userPwd') {
            this.passLogin.password = arr2[1]; //可解密
          } else if (arr2[0] === 'isRemember') {
            this.isRemember = Boolean(arr2[1]);
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.login {
  height: 485px;
  // logo
  &-logo {
    margin: -30px auto 20px;
    width: 178px;
    height: 43px;
    background-size: contain;
    background-image: url('~a/login-logo.png');
  }
  // 分割线
  &-border {
    margin: 0 auto 0;
    width: 380px;
    height: 1px;
    background: #e9f2fb;
  }
  // 标题
  &-title {
    display: flex;
    align-content: space-between;
    margin-top: 30px;
    &-pass {
      width: 80px;
      margin-left: 56px;
    }
    &-note {
      width: 130px;
      margin-left: 100px;
    }
    .el-button {
      font-size: 20px;
      font-weight: 400;
      color: #666666;
    }
    &-border {
      margin: 18px auto 34px;
      width: 50px;
      height: 1px;
      background: #0096ff;
    }
  }
  // 记住密码 忘记密码
  &-password {
    display: flex;
    justify-content: space-between;
    /deep/.el-checkbox__label {
      font-size: 14px !important;
      color: #666666;
    }
    &-forget {
      display: flex;
      font-size: 14px;
      color: #0096ff !important;
      cursor: pointer;
    }
  }
  // 输入框
  /deep/&-input {
    height: 52px;
    margin: 0 20px;
    .el-input__inner {
      width: 360px;
      height: 52px;
      background: #ffffff;
      border: 1px solid #999999;
      border-radius: 4px;
      padding-left: 47px;
      font-size: 16px;
    }
    // 表单验证错误提示
    .el-form-item__error {
      margin-top: 5px;
      font-size: 12px;
    }
    .formItem {
      margin-bottom: 28px;
    }
    // 输入框icon
    .icon {
      display: flex;
      margin: 13px 0 0 6px;
      width: 22px;
      height: 22px;
    }
    .login-user {
      background-image: url('~a/login-user.png');
    }
    .login-pass {
      background-image: url('~a/login-pass.png');
    }
    .login-verify {
      margin-left: 35px;
      background-image: url('~a/login-verify.png');
    }
    // 获取验证码
    &-fsdx {
      width: 160px;
      height: 44px;
      text-align: center;
      position: absolute;
      z-index: 1;
      margin: 4px 0 0 230px;
      font-size: 16px;
      color: #0096ff;
      border: none;
    }
    &-border {
      position: absolute;
      z-index: 10;
      margin: 16px 0 0 257px;
      width: 1px;
      height: 18px;
      background: #999999;
    }
    // 国际区号
    &-areaCode {
      position: absolute;
      margin-top: -5px;
      z-index: 3;
      .flag-list-box {
        background: #ffffff;
      }
    }
  }
  // 短信登录
  .nodeInput {
    margin-bottom: 28px;
    /deep/.el-input__inner {
      padding-left: 100px;
    }
  }

  // 登录按钮
  &-button {
    margin: 130px 20px 0;
    .el-button {
      width: 360px;
      height: 40px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #0096ff;
      background: #0096ff;
      color: #ffffff;
    }
  }
  // 底部说明
  &-explain {
    display: flex;
    align-items: center;
    margin: 20px 0 0 40px;
    height: 20px;
    &-img {
      width: 20px;
    }
    &-text {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      margin-left: 10px;
      white-space: nowrap;
    }
  }
}
.code {
  margin-top: 100px;
}
.el-dropdown-menu__item:hover {
  background: none !important;
  color: #333333 !important;
}
/deep/.el-form-item__content {
  height: 45px;
}
/deep/.el-dialog {
  height: 485px;
  &__header {
    border: none;
    .el-icon-close:before {
      font-size: 24px;
      font-weight: 800px;
    }
  }
}
</style>
