export const userRulesMixin = {
  data() {
    // 验证手机的规则
    var checkMobeli = (rule, value, callback) => {
      const regMobile =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (regMobile.test(value)) {
        return callback();
      }
      // 返回一个错误提示
      callback(new Error('手机号码格式不正确，请重新输入'));
    };
    // 验证电话的规则
    var checkPhone = (rule, value, callback) => {
      const regMobile =
        /^1\d{10}$|^(0\d{2,3}-?|\(0\d{2,3}\))?[1-9]\d{4,7}(-\d{1,8})?$/;
      if (regMobile.test(value)) {
        return callback();
      }
      // 返回一个错误提示
      callback(
        new Error('电话或手机号码格式不正确，请重新输入,例如 0755-86336555')
      );
    };
    // 仅允许英文字母、数字或特殊符号
    var checkEuserNum = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          callback(new Error('仅允许英文字母、数字或特殊符号'));
        } else {
          callback();
        }
      }
      callback();
    };
    return {
      // 登录表单的验证规则对象
      userFormRules: {
        user: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          },
          { validator: checkMobeli, trigger: 'blur' }
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur'
          },
          { validator: checkMobeli, trigger: 'blur' }
        ],
        password: [
          {
            required: true,
            message: '密码长度为 5~20 字符，允许英文字母、数字或特殊符号',
            trigger: 'blur'
          },
          {
            min: 5,
            max: 20,
            message: '密码长度为 5~20 字符，允许英文字母、数字或特殊符号',
            trigger: 'blur'
          },
          { validator: checkEuserNum, trigger: 'blur' }
        ]
      },
      // 认证表单的验证规则对象
      authenticationFormRules: {
        name: [
          {
            required: true,
            message: '请输入您的姓名',
            trigger: 'blur'
          },
          {
            required: true,
            min: 2,
            max: 40,
            message: '姓名只允许填写中文、英文，长度为2-40个字符',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入您的手机号码',
            trigger: 'blur'
          },
          { validator: checkMobeli, trigger: 'blur' }
        ],
        phone: [
          {
            required: true,
            message: '请输入您的电话号码',
            trigger: 'blur'
          },
          { validator: checkPhone, trigger: 'blur' }
        ],
        email: [
          {
            required: true,
            message: '请输入您的邮箱号码',
            trigger: 'blur'
          },
          { type: 'email', message: '请填写正确的电子邮箱' }
        ],
        address: [
          {
            required: true,
            message: '请选择您的地址',
            trigger: 'blur'
          }
        ],
        IDnumber: [
          {
            required: true,
            message: '请输入您的身份证号码',
            trigger: 'blur'
          },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: '请填写正确的身份证号码'
          }
        ],
        companyName: [
          {
            required: true,
            message: '请输入您的企业名称',
            trigger: 'blur'
          }
        ],
        companyAddress: [
          {
            required: true,
            message: '请输入您的企业地址',
            trigger: 'blur'
          }
        ],
        credit: [
          {
            required: true,
            message: '请输入您的社会信用代码',
            trigger: 'blur'
          }
        ],
        img: [
          {
            required: true,
            message: '请上传您的营业执照图片',
            trigger: 'blur'
          }
        ]
      },

      // 素材库表单验证规则
      materialLibraryFormRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        categoryId: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ],
        // 图标库
        url: [{ required: true, message: '请上传图标', trigger: 'blur' }],
        // 多边形库
        frameWidth: [
          { required: true, message: '请输入边宽', trigger: 'blur' }
        ],
        // 样式库-导入文件
        localFileName: [
          { required: true, message: '请输入导入文件', trigger: 'blur' }
        ],
        // 样式库-代码
        styleContent: [
          { required: true, message: '请校验您的代码', trigger: 'blur' }
        ]
      }
    };
  }
};
