<template>
  <div class="page">
    <Header @openNoticeDialog="noticeDialogVisible = true" />
    <!-- style="position: fixed" -->
    <router-view class="view" @openNoticeDialog="noticeDialogVisible = true" />
    <right />
    <!-- 用户登录-注册窗口 -->
    <Login />
    <!-- 选择认证窗口 -->
    <SelectiveAuthenticate />
    <!-- 个人认证窗口 -->
    <PersonalCertificate />
    <!-- 企业认证窗口 -->
    <EnterpriseCertification />
    <!-- 关于我们窗口 -->
    <!-- <AboutUs /> -->
    <!-- 故障提交 -->
    <CommitFailuresDialog v-if="commitFailuresDialogVisible" />
    <!-- 产品建议 -->
    <ProductSuggestionsDialog v-if="productSuggestionsDialogVisible" />
    <NoticeDialog
      v-if="noticeDialogVisible"
      @close="noticeDialogVisible = false"
    />
  </div>
</template>

<script>
import NoticeDialog from '@/components/NoticeDialog.vue';
import Header from '@/components/Header.vue';
import Right from '@/components/Right.vue';
import Login from 'c/userCommon/Login.vue';
import SelectiveAuthenticate from 'c/userCommon/SelectiveAuthenticate.vue';
import PersonalCertificate from 'c/userCommon/PersonalCertificate';
import EnterpriseCertification from 'c/userCommon/EnterpriseCertification';
// import AboutUs from '../components/workbench/AboutUs.vue';
import CommitFailuresDialog from './CommitFailuresDialog.vue';
import ProductSuggestionsDialog from './ProductSuggestionsDialog.vue';
import { mapState } from 'vuex';
export default {
  name: 'Page',
  components: {
    NoticeDialog,
    Header,
    Right,
    Login,
    SelectiveAuthenticate,
    PersonalCertificate,
    EnterpriseCertification,
    // AboutUs,
    CommitFailuresDialog,
    ProductSuggestionsDialog
  },
  data() {
    return {
      noticeDialogVisible: false
    };
  },
  computed: {
    ...mapState('userData', [
      'commitFailuresDialogVisible',
      'productSuggestionsDialogVisible'
    ])
  }
};
</script>

<style lang="less" scoped>
.view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
