import axios from 'p/axios';
import qs from 'qs';
/* eslint-disable */
// 图层相关
// 创建图层
const layerCreate = data => {
  return axios.post('/layer/create', qs.stringify(data));
};

// 删除图层
const layerDel = data => {
  return axios.post('/layer/del', qs.stringify(data));
};

// 重命名图层
const layerModifyName = data => {
  return axios.post('/layer/modifyName', qs.stringify(data));
};

// 移动图层
const layerMove = data => {
  return axios.post('/layer/move', qs.stringify(data));
};

// 按树形结构查询所有接口
const layerListTree = data => {
  return axios.get('/layer/list/tree', { params: data });
};

// 获取图层信息接口
const layerGet = data => {
  return axios.get('/layer/get', { params: data });
};
// 设置图层属性接口
const layerAttrSet = data => {
  return axios.post('/layer/attr/set', qs.stringify(data));
};

// 获取图层和样式信息
const layerGetDetailAndStyle = data => {
  return axios.get('/layer/getDetailAndStyle', { params: data });
};

// 数据相关
// 查询图层数据接口
const layerDataList = data => {
  return axios.get('/layer/data/list', { params: data });
};
// 添加数据接口
const layerDataAdd = data => {
  return axios.post('/layer/data/add', qs.stringify(data));
};

// 移除数据
const layerDataRemove = data => {
  return axios.post('/layer/data/remove', qs.stringify(data));
};

// 加载图层数据
const layerDataLoad = params => axios.get('/layer/data/load/tree', { params });

// 根据上级id查询图层
const layerGetByParent = params => axios.get('/layer/getByParent', { params });

export default {
  layerGetByParent,
  layerDataLoad,
  layerCreate,
  layerDel,
  layerModifyName,
  layerMove,
  layerListTree,
  layerDataAdd,
  layerDataList,
  layerGet,
  layerAttrSet,
  layerGetDetailAndStyle,
  layerDataRemove
};
