// 表单通用校验规则

// 指定长度字符串
const strVerify = ({ required = true, msg, len, min }) => {
  let ruler = [];
  if (required) {
    ruler.push({ required, message: msg, trigger: 'blur' });
  }
  let lenMsg = '';
  if (len && min) {
    lenMsg = `请输入${min}~${len}个的字符`;
  }
  if (len && !min) {
    lenMsg = `最长${len}个字符`;
  }
  if (!len && min) {
    lenMsg = `最短${len}个字符`;
  }
  if (lenMsg) {
    ruler.push({ max: len, min, message: lenMsg, trigger: 'blur' });
  }
  return ruler;
};

// 手机号
const phoneVerify = () => {
  return {
    validator: validatePhone,
    message: '请输入格式正确的手机号',
    trigger: 'blur'
  };
};
function validatePhone(rule, value, callback) {
  if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(value) || !value) {
    callback();
  } else {
    callback(new Error('手机格式错误'));
  }
}

// 网址
const websiteVerify = () => {
  return {
    validator: validateWebsite,
    message: '请输入格式正确的网址',
    trigger: 'blur'
  };
};
function validateWebsite(rule, value, callback) {
  if (
    /^(((ht|f)tps?):\/\/)?([^!@#$%^&*?.\s-]([^!@#$%^&*?.\s]{0,63}[^!@#$%^&*?.\s])?\.)+[a-z]{2,6}\/?/.test(
      value
    ) ||
    !value
  ) {
    callback();
  } else {
    callback(new Error('网址格式错误'));
  }
}

// 数字
const numberVerify = () => {
  return {
    validator: validateNumber,
    message: '请输入格式正确的数字',
    trigger: 'blur'
  };
};
function validateNumber(rule, value, callback) {
  if (/^(-|\+)?\d+(\.\d+)?$/.test(value) || !value) {
    callback();
  } else {
    callback(new Error('数字格式错误'));
  }
}

export { strVerify, phoneVerify, websiteVerify, numberVerify };
