import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import services from './services';
import { v4 as uuidv4 } from 'uuid';
import './plugins/element.js';
import './plugins/echarts';
// 注释掉default-passive-events包的背景移动警告
import 'default-passive-events';
// 重置浏览器默认样式
import './style/reset.less';
// icon小图标
import './style/icon.less';
// iconfont图标库
import './style/iconfont.css';
// 自定义element组件样式
import './style/element.less';
// 自定义平台标题样式
import './style/home.less';
// 自定义动画
import './style/animate.less';
// 自定义指令
import './plugins/directive';
// 扩展NUmber对象方法
import './plugins/Number';

// 数据管理属性表拖拽滑动
import tableMove from './plugins/tableMove';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);
Vue.use(tableMove);

Vue.config.productionTip = false;
Vue.prototype.$uuid = uuidv4;
Vue.prototype.$service = services;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
