<template>
  <!-- 个人认证开发者 -->
  <el-dialog
    :visible.sync="personalCertificateDialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    class="Authentication-grrz"
    top="20vh"
  >
    <!-- 个人认证标题 -->
    <h1 class="Authentication-title Authentication-grrz-title">个人认证</h1>
    <p class="Authentication-text">认证后即可使用服务发布功能</p>
    <div class="Authentication-grrz-border"></div>
    <el-form
      :model="grrzForm"
      class="Authentication-grrz-form"
      ref="grrzRuleForm"
      :rules="authenticationFormRules"
      label-width="8.2vw"
    >
      <el-form-item label="姓名：" prop="name">
        <el-input v-model="grrzForm.name" maxlength="40"></el-input>
      </el-form-item>
      <el-form-item label="手机号：" prop="mobile">
        <el-input v-model="grrzForm.mobile" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：" prop="email" maxlength="30">
        <el-input v-model="grrzForm.email"></el-input>
      </el-form-item>
      <el-form-item label="地址：" prop="address">
        <el-cascader
          size="large"
          :options="options"
          v-model="selectedOptions"
          @change="handleChange"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="身份证号：" prop="IDnumber">
        <el-input v-model="grrzForm.IDnumber" maxlength="18"></el-input>
      </el-form-item>
      <el-form-item>
        <div class="Authentication-grrz-form-btn">
          <el-button
            class="btn-reelect"
            @click="reelectClick"
            v-show="!recognition"
            >重选认证方式</el-button
          >
          <el-button
            class="btn-submit"
            @click="grrzClick('grrzRuleForm')"
            :style="{ marginLeft: [recognition ? '10.5vw' : ''] }"
            >提交材料</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
/* eslint-disable */
// 地区选择器
import { regionData, CodeToText } from 'element-china-area-data';
import { userRulesMixin } from '@/plugins/mixin';
export default {
  name: 'Authentication',
  mixins: [userRulesMixin],
  props: ['recognition'],
  data() {
    return {
      options: regionData, //地区数据
      selectedOptions: ['440000', '440300', '440305'], //地区数据
      // 个人认证表单数据
      grrzForm: {
        name: '神州龙',
        mobile: '15326548411',
        email: 'info@longmap.com',
        address: '1',
        IDnumber: '44030520220719668X'
      }
    };
  },
  computed: {
    personalCertificateDialogVisible: {
      get() {
        return this.$store.state.userData.personalCertificateDialogVisible;
      },
      set(val) {
        this.$store.commit('userData/setPersonalCertificateDialogVisible', val);
      }
    }
  },
  methods: {
    // 个人认证
    grrzClick(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.recognition) {
            this.$message.success('提交成功!');
          } else {
            this.$router.push({ path: '/workbench/workspace' });
          }
          this.$store.commit(
            'userData/setPersonalCertificateDialogVisible',
            false
          );
        } else {
          return false;
        }
      });
    },
    // 重选
    reelectClick() {
      this.$store.commit(
        'userData/setSelectiveAuthenticateDialogVisible',
        true
      );
      setTimeout(() => {
        this.$store.commit(
          'userData/setPersonalCertificateDialogVisible',
          false
        );
      }, 100);
    },
    // 地区
    handleChange() {
      var loc = '';
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
      this.grrzForm.address = loc;
    }
  }
};
</script>

<style lang="less" scoped>
.Authentication {
  overflow: hidden;
  // 标题
  &-title {
    margin-top: -20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  // 标题说明
  &-text {
    margin-top: 8px;
    text-align: center;
    font-size: 16px;
    color: #999999;
  }
  // 个人认证窗口
  &-grrz {
    /deep/.el-dialog {
      width: 653px;
      overflow: hidden;
      &__header {
        border: none;
      }
    }
    &-title {
      margin-top: -35px;
    }
    &-border {
      margin: 30px -50px 20px;
      width: 100% + 18;
      height: 1px;
      background: #e5e5e5;
    }
    // 个人认证表单
    /deep/&-form {
      .el-form-item {
        margin: 0 0 20px 0;
        height: 36px;
        &__label {
          line-height: 36px;
          font-size: 18px;
          color: #333333;
        }
        &__content {
          display: flex;
          align-items: center;
        }
        &__error {
          font-size: 12px;
          padding-top: 0;
        }
      }
      .el-input {
        height: 36px;
        width: 362px;
        margin: 0;
        &__inner {
          font-size: 16px;
          background: #ffffff;
          border: 1px solid #999999;
          border-radius: 4px;
          padding: 0 16px;
        }
      }
      &-btn {
        display: flex;
        justify-content: space-between;
        width: 363px;
        height: 40px;
        margin: 8px 0;
      }
    }
    .btn-reelect,
    .btn-submit {
      width: 160px;
      height: 40px;
      border: none;
      font-size: 16px;
    }
    .btn-reelect {
      border: 1px solid #999999;
      background: #ffffff;
      color: #999999;
    }
    .btn-submit {
      border: 1px solid #0096ff;
      background: #0096ff;
      color: #ffffff;
    }
  }
}
</style>