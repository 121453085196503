<template>
  <router-view id="app" />
</template>

<script>
export default {
  name: 'app',
  created() {
    document.body.addEventListener('contextmenu', function (e) {
      e.preventDefault();
    });
    if (!this.IsPC()) {
      location.href = window.promoteHomePage;
    }
  },
  methods: {
    // 判断客户端类型
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod'
      ];

      var flagPc = true;

      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flagPc = false;
          break;
        }
      }

      return flagPc;
    }
  }
};
</script>

<style lang="less" scoped>
#app {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
</style>
