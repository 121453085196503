import Vue from 'vue';
import router from '../router';
import axios from 'axios';

let config = {
  baseURL: window.BASE_URL
};

const _axios = axios.create(config);

let loginMsgState = false;
let errorMsgState = false;

_axios.interceptors.request.use(
  function (config) {
    const url = config.url;
    // 登录相关接口传递临时token
    if (url.indexOf('/workbench/') !== -1) {
      config.headers.Authorization = localStorage.token;
    } else {
      config.headers.Authorization = localStorage.token;
    }
    return config;
  },
  function (error) {
    this.$message.error('出错了，请稍后再试');
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    if (response.status === 200) {
      const status = response.data.status;
      if (status !== 200) {
        let message = response.data.msg;
        if ([406, 407].includes(status)) {
          if (!loginMsgState) {
            loginMsgState = true;
            message = '需要登录，2秒后将跳转到登录界面！';
            // 清空token
            localStorage.userName = '';
            localStorage.token = '';

            localStorage.redirectUrl = window.location.href;
            setTimeout(() => {
              router.push('/login');
            }, 2000);
          } else {
            message = '';
          }
        }
        //请求状态吗不是200，展示错误信息
        if (message) {
          Vue.prototype.$message({
            type: 'warning',
            message,
            duration: 2000
          });
          // $message默认3s后消失，消失后，重置需要登录消息提示
          if (loginMsgState) {
            setTimeout(() => {
              loginMsgState = false;
            }, 200);
          }
        }
      }
      return response.data;
    } else {
      return response;
    }
  },
  function (error) {
    // 请求失败提示
    if (!errorMsgState) {
      errorMsgState = true;
      Vue.prototype.$message({
        type: 'error',
        message: '出错了，请稍后再试',
        duration: 2000
      });
      setTimeout(() => {
        errorMsgState = false;
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export default _axios;
