import axios from 'p/axios_cl';
import qs from 'qs';

const config = {
  baseURL: window.quotaIp,
  paramsSerializer: {
    serialize(params) {
      // get请求传递数组
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  }
};

// 查询套餐包
const packageList = params =>
  axios.get('/quota/package', {
    params,
    ...config
  });

// 查询套餐包价格
const packagePrice = params =>
  axios.get('/quota/package/price', { params, ...config });

// 创建订单
const orderCreate = params =>
  axios.post('/order/create', qs.stringify(params), { ...config });

// 我的卡券
const couponMy = params => axios.get('/coupon/my', { params, ...config });

// 当前操作适用的券
const couponApplies = params =>
  axios.get('/coupon/applies', { params, ...config });

// 我的配额
const quotaUser = () => axios.get('/user/my', { ...config });

export default {
  quotaUser,
  couponApplies,
  couponMy,
  orderCreate,
  packageList,
  packagePrice
};
