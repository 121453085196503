<template>
  <el-dialog
    class="productSuggestionsDialog"
    :visible.sync="productSuggestionsDialogVisible"
    :before-close="
      () => {
        productSuggestionsDialogVisible = false;
      }
    "
  >
    <template #title>
      <p class="productSuggestionsDialog-title">产品建议</p>
    </template>
    <el-form class="commitFailures-form" :rules="rules">
      <el-form-item class="customFormLabel" prop="title">
        <template #label>
          <div class="form-label">
            <img src="~a/form-tip.png" alt="" />
            <span class="red">*</span>
            <span>标题</span>
          </div>
        </template>
        <el-input
          v-model="from.title"
          placeholder="清晰地标题会更快被核实采纳"
        />
      </el-form-item>
      <el-form-item class="customFormLabel" prop="title">
        <template #label>
          <div class="form-label">
            <img src="~a/form-tip.png" alt="" />
            <span class="red">*</span>
            <span>补充描述</span>
          </div>
        </template>
        <el-input
          type="textarea"
          v-model="from.remark"
          :rows="5"
          placeholder="请详细描述您的建议使用场景及优化/改进方案（不少于15字）"
        />
      </el-form-item>
      <el-form-item class="customFormLabel">
        <template #label>
          <div class="form-label">
            <img src="~a/form-tip.png" alt="" />
            <span>相关截图/图片</span>
          </div>
        </template>
        <el-upload
          action="https://jsonplaceholder.typicode.com/posts/"
          list-type="picture-card"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item class="customFormLabel" prop="phone">
        <template #label>
          <div class="form-label">
            <img src="~a/form-tip.png" alt="" />
            <span class="red">*</span>
            <span>联系方式</span>
          </div>
        </template>
        <el-input v-model="from.phone" placeholder="方便我们随时与您沟通" />
      </el-form-item>
    </el-form>
    <template #footer>
      <Button class="productSuggestionsDialog-btn">提交</Button>
    </template>
  </el-dialog>
</template>

<script>
import { strVerify, phoneVerify } from 'p/formVerify';
import Button from 'c/Button';
export default {
  name: 'ProductSuggestionsDialog',
  components: {
    Button
  },
  props: {
    visible: Boolean
  },
  data() {
    return {
      from: {
        title: '',
        remark: '',
        imgs: [],
        phone: ''
      },
      rules: {
        title: strVerify({ msg: '请输入标题' }),
        remark: strVerify({ msg: '请输入补充描述', min: 15 }),
        phone: phoneVerify()
      }
    };
  },
  computed: {
    productSuggestionsDialogVisible: {
      get() {
        return this.$store.state.userData.productSuggestionsDialogVisible;
      },
      set(val) {
        this.$store.commit('userData/setProductSuggestionsDialogVisible', val);
      }
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    }
  }
};
</script>

<style lang="less" scoped>
.productSuggestionsDialog {
  /deep/ .el-dialog {
    &__header {
      height: 90px;
      border-color: #e5e5e5;
    }
    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      border-top: 1px solid #e5e5e5;
    }
  }
  &-title {
    line-height: 60px;
    color: #333;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .customFormLabel {
    /deep/ .el-form-item {
      &__label {
        display: flex;
        align-items: center;
        width: 100%;
        height: 36px;
        margin-bottom: 16px;
        background-color: #fff8e8;
        border-radius: 4px;
        &::before {
          display: none;
        }
      }
      &__content {
        margin-left: 46px;
        textarea {
          font-size: 16px;
          font-family: Arial, Helvetica, sans-serif;
          &::placeholder {
            color: #999;
          }
        }
      }
    }
    /deep/ .el-upload {
      width: 86px;
      height: 86px;
      line-height: 86px;
      background: #ffffff;
      border: 1px solid #999999;
      border-radius: 4px;
      &-list {
        &__item {
          width: 86px;
          height: 86px;
        }
      }
    }
    &-tip {
      color: #666666;
      font-size: 18px;
    }
  }
  .form-label {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
  &-btn {
    width: 150px;
    height: 40px;
    background: #0096ff;
    border-radius: 4px;
    font-size: 16px;
  }
}
</style>
