/* eslint-disable */
import axios from 'p/axios';
import qs from 'qs';

// 查询对应目录数据集（下拉框）
const datasetGetAll = data => {
  return axios.get('/dataset/getAll', { params: data })
}

// 查询所有数据，并且按类型分组返回
const datasetGetGroup = data => {
  return axios.get('/dataset/getGroup', { params: data })
}

// 添加数据集
const datasetAdd = data => {
  return axios.post('/dataset/add', qs.stringify(data))
}

// 修改数据集
const datasetEdit = data => {
  return axios.post('/dataset/edit', qs.stringify(data))
}

// 重命名数据集
const datasetModifyName = data => {
  return axios.post('/dataset/modifyName', qs.stringify(data))
}

// 删除数据集
const datasetDel = data => {
  return axios.post('/dataset/del', qs.stringify(data))
}

// 拷贝数据集
const datasetCopy = data => {
  return axios.post('/dataset/copy', qs.stringify(data))
}

// 获取数据集
const datasetGet = data => {
  return axios.post('/dataset/Get', qs.stringify(data))
}

// 要素类

// 获取要素类
const featureClassGetByDataset = data => {
  return axios.get('/featureClass/getByDataset', { params: data })
}

// 添加要素类
const featureClassAdd = data => {
  return axios.post('/featureClass/add', qs.stringify(data))
}

// 编辑要素类
const featureClassEdit = data => {
  return axios.post('/featureClass/edit', qs.stringify(data))
}

// 移动要素类
const featureClassMove = data => {
  return axios.post('/featureClass/move', qs.stringify(data))
}

// 删除要素类
const featureClassDel = data => {
  return axios.post('/featureClass/del', qs.stringify(data))
}

// 获取要素类信息
const featureClassGetById = data => {
  return axios.get('/featureClass/getById', { params: data })
}

// 修改要素类名称
const featureClassRename = data => {
  return axios.post('/featureClass/rename', qs.stringify(data))
}

// 设置要素类属性(贴地)
const featureClassAttrSet = data => {
  return axios.post('/featureClass/attr/set', qs.stringify(data))
}

// 获取自定义要素类属性字段
const featureClassField = data => {
  return axios.get('/featureClass/field', { params: data })
}

// 设置要素类属性
const featureClassFieldEdit = data => {
  return axios.post('/featureClass/field/edit', qs.stringify(data))
}

// 获取属性
const featureClassAttr = data => {
  return axios.get('/featureClass/attr', { params: data })
}

// 获取要素类点属性表
const featurePointAttributeTable = data => {
  return axios.get('/feature/point/attributeTable', { params: data })
}


// 获取要素类线属性表
const featurePolygonAttributeTable = data => {
  return axios.get('/feature/polygon/attributeTable', { params: data })
}

// 获取要素类面属性表
const featureLineAttributeTable = data => {
  return axios.get('/feature/line/attributeTable', { params: data })
}

// 删除点属性数据
const featurePointDel = data => {
  return axios.post('/feature/point/del', qs.stringify(data))
}
// 删除线属性数据
const featureLinetDel = data => {
  return axios.post('/feature/line/del', qs.stringify(data))
}
// 删除面属性数据
const featurePolygonDel = data => {
  return axios.post('/feature/polygon/del', qs.stringify(data))
}

// 保存点属性数据
const featurePointAttributeTableEdit = data => {
  return axios.post('/feature/point/attributeTable/edit', qs.stringify(data))
}
// 保存线属性数据
const featureLineAttributeTableEdit = data => {
  return axios.post('/feature/line/attributeTable/edit', qs.stringify(data))
}
// 保存面属性数据
const featurePolygonAttributeTableEdit = data => {
  return axios.post('/feature/polygon/attributeTable/edit', qs.stringify(data))
}

// 获取点信息
const featurePointGet = data => {
  return axios.get('/feature/point/get', { params: data })
}

// 获取线信息
const featureLineGet = data => {
  return axios.get('/feature/line/get', { params: data })
}

// 获取面信息
const featurePolygonGet = data => {
  return axios.get('/feature/polygon/get', { params: data })
}



const loadFeature = () => { };
const loadRaster = () => { };
const getVideoData = () => { };

export default {
  datasetGetAll,
  datasetGetGroup,
  datasetAdd,
  datasetEdit,
  datasetModifyName,
  datasetDel,
  datasetCopy,
  datasetGet,
  featureClassGetByDataset,
  featureClassAdd,
  featureClassEdit,
  featureClassMove,
  featureClassDel,
  featureClassRename,
  featureClassAttrSet,
  featureClassAttr,
  featureClassGetById,
  featureClassFieldEdit,
  featureClassField,
  featurePointAttributeTable,
  featurePolygonAttributeTable,
  featureLineAttributeTable,
  featurePointDel,
  featureLinetDel,
  featurePolygonDel,
  featurePointAttributeTableEdit,
  featureLineAttributeTableEdit,
  featurePolygonAttributeTableEdit,
  featurePointGet,
  featureLineGet,
  featurePolygonGet,

  loadFeature, loadRaster, getVideoData
};
