import axios from 'p/axios';
import qs from 'qs';

const fileIp = window.fileIp;
const losIp = window.losIp;

// 创建文件夹
const fileMkdir = data => {
  return axios.post('/user/file/mkdir', qs.stringify(data), {
    baseURL: fileIp
  });
};

// 修改文件（夹）名称
const fileModifyName = data => {
  return axios.post('/user/file/modifyName', qs.stringify(data), {
    baseURL: fileIp
  });
};

// 上传单个文件
const fileUploadSingleFile = data => {
  return axios.post('/user/file/uploadSingleFile', data, {
    baseURL: fileIp
  });
};

// 上传文件夹
const fileUploadFolder = data => {
  return axios.post('/user/file/uploadFolder', data, {
    baseURL: fileIp
  });
};

// 移动文件(夹)
const fileMove = data => {
  return axios.post('/user/file/move', qs.stringify(data), { baseURL: fileIp });
};

// 批量移动文件(夹)
const fileMoveBatch = data => {
  return axios.post('/user/file/move/batch', data, {
    baseURL: fileIp,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// 删除文件(夹)
const fileDelete = data => {
  return axios.post('/user/file/delete', data, {
    baseURL: fileIp,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// 批量删除文件(夹)
const fileDeleteBatch = data => {
  return axios.post('/user/file/delete/batch', data, {
    baseURL: fileIp,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// 复制、移动
const fileMoveOrCopy = data => {
  return axios.get('/user/file/folder/moveOrCopy', {
    params: data,
    baseURL: fileIp
  });
};

// 我的文件
const fileMy = data => {
  return axios.get('/user/file/my', { params: data, baseURL: fileIp });
};

// 搜索文件
const fileSearch = data => {
  return axios.get('/user/file/search', { params: data, baseURL: fileIp });
};

// 是否可以上传
const fileCanUpload = data => {
  return axios.get('/user/file/canUpload', { params: data, baseURL: fileIp });
};

// 存储空间使用情况
const fileSpace = data => {
  return axios.get('/user/file/space', { params: data, baseURL: fileIp });
};

// 获取切片信息
const fileUploadPart = data => {
  return axios.get('/file/upload/part', { params: data, baseURL: fileIp });
};

// 取消单个上传任务
const fileUploadCancel = data => {
  return axios.post('/file/upload/cancel', qs.stringify(data), {
    baseURL: fileIp
  });
};

// 取消所有上传任务
const fileUploadCancelAll = () => {
  return axios.post('/file/upload/cancel/all', {}, { baseURL: fileIp });
};

// 上传切片接口
const fileUploadPartPost = (data, config) => {
  return axios.post('/file/upload/part', data, { baseURL: fileIp, ...config });
};

// 上传其他文件（大小不超过5M）
const fileUploadOther = data => {
  return axios.post('/file/upload/other', data, {
    baseURL: fileIp,
    timeout: 24 * 60 * 60 * 1000
  });
};

// 获取样式库的sid文件
const getStyleSLD = data => {
  return axios.get(data, { baseURL: losIp, responseType: 'blob' });
};

export default {
  fileMkdir,
  fileModifyName,
  fileUploadSingleFile,
  fileUploadFolder,
  fileMove,
  fileMoveBatch,
  fileDelete,
  fileDeleteBatch,
  fileMy,
  fileMoveOrCopy,
  fileSpace,
  fileSearch,
  fileCanUpload,
  fileUploadPart,
  fileUploadCancel,
  fileUploadCancelAll,
  fileUploadPartPost,
  fileUploadOther,
  getStyleSLD
};
