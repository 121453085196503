<template>
  <!-- 企业认证开发者 -->
  <el-dialog
    :visible.sync="enterpriseCertificationDialogVisible"
    :close-on-click-modal="false"
    destroy-on-close
    class="EnterpriseCertification-qyrz"
    top="3vh"
  >
    <!-- 企业认证标题 -->
    <h1 class="EnterpriseCertification-title">企业认证</h1>
    <p class="EnterpriseCertification-text">认证后即可使用服务发布功能</p>
    <div class="EnterpriseCertification-qyrz-border"></div>
    <el-form
      :model="qyrzForm"
      class="EnterpriseCertification-qyrz-form"
      ref="qyrzRuleForm"
      label-width="10.2vw"
      :rules="authenticationFormRules"
    >
      <el-form-item label="联系人：" prop="name">
        <el-input v-model="qyrzForm.name" maxlength="40"></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" prop="mobile">
        <el-input v-model="qyrzForm.mobile" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="企业名称：" prop="companyName">
        <el-input v-model="qyrzForm.companyName" maxlength="100"></el-input>
      </el-form-item>
      <el-form-item label="企业电话：" prop="phone">
        <el-input v-model="qyrzForm.phone" maxlength="13"></el-input>
      </el-form-item>
      <el-form-item label="企业地址：" prop="companyAddress">
        <el-cascader
          size="large"
          :options="options"
          v-model="selectedOptions"
          @change="handleChange"
          placeholder=""
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="营业执照号：" prop="credit">
        <el-input v-model="qyrzForm.credit" maxlength="18"></el-input>
      </el-form-item>
      <div class="EnterpriseCertification-qyrz-border1"></div>
      <el-form-item label="上传营业执照图片：" prop="img">
        <div class="EnterpriseCertification-qyrz-form-img">
          <div class="upload">
            <el-upload
              drag
              :action="action"
              :limit="1"
              :before-upload="beforeUploadFunction"
              :on-success="handleUploadSuccess"
              :on-exceed="handleExceed"
              :on-remove="handleRemove"
              :on-progress="uploadVideoProcess"
            >
              <img v-if="qyrzForm.img" :src="qyrzForm.img" class="UploadPic" />
              <div v-else>
                <img src="@/assets/icon-businessLicense.png" class="img" />
                <div class="el-upload__text">
                  <span> 点击上传营业执照</span>
                  <p>（或将文件拖到此处）</p>
                </div>
              </div>
            </el-upload>
          </div>
          <div class="example">
            <img src="@/assets/yyzz.png" class="img" />
          </div>
        </div>
        <p class="EnterpriseCertification-qyrz-form-img-requested">
          <span>图片要求：</span>单张图片不大于5M; &emsp;&emsp;
          <i
            class="el-icon-success"
            style="color: #29fe00; margin-right: 0.25vw"
          ></i
          ><span>正确示例</span>
        </p>
        <p
          class="EnterpriseCertification-qyrz-form-img-requested EnterpriseCertification-qyrz-form-img-requested1"
        >
          <span>拍摄要求：</span
          >请保持证照表面无反光，边框清晰光&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;整，背景干净。
        </p>
      </el-form-item>
      <div class="EnterpriseCertification-qyrz-border2"></div>
      <el-form-item>
        <div class="EnterpriseCertification-qyrz-form-btn">
          <el-button
            class="btn-reelect"
            @click="
              reelectClick('qyrzRuleForm');
              enterpriseCertificationDialogVisible = false;
            "
            v-show="!recognition"
            >重选认证方式</el-button
          >
          <el-button
            class="btn-submit"
            @click="grrzClick('qyrzRuleForm')"
            :style="{ marginLeft: [recognition ? '10.5vw' : ''] }"
            >提交材料</el-button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data';
import { userRulesMixin } from '@/plugins/mixin';
export default {
  name: 'EnterpriseCertification',
  mixins: [userRulesMixin],
  props: ['recognition'],
  data() {
    return {
      options: regionData, //地区数据
      selectedOptions: [], //地区数据
      action: window.uploadIp + '/los/upload/batch/openFile', //上传营业执照
      // 企业认证表单
      qyrzForm: {
        name: '',
        mobile: '',
        phone: '',
        companyName: '',
        companyAddress: '',
        credit: '',
        img: ''
      }
    };
  },
  computed: {
    enterpriseCertificationDialogVisible: {
      get() {
        return this.$store.state.userData.enterpriseCertificationDialogVisible;
      },
      set(val) {
        this.$store.commit(
          'userData/setEnterpriseCertificationDialogVisible',
          val
        );
      }
    }
  },
  methods: {
    // 企业认证
    grrzClick(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.recognition) {
            this.$message.success('提交成功!');
          } else {
            this.$router.push({ path: '/workbench/workspace' });
          }
          this.$store.commit(
            'userData/setEnterpriseCertificationDialogVisible',
            false
          );
        } else {
          return false;
        }
      });
    },
    // 重选
    reelectClick() {
      setTimeout(() => {
        this.$store.commit(
          'userData/setEnterpriseCertificationDialogVisible',
          false
        );
      }, 100);
      this.$store.commit(
        'userData/setSelectiveAuthenticateDialogVisible',
        true
      );
    },

    //上传文件大小和格式校验
    beforeUploadFunction(file) {
      const _name = file.name;
      const _index = _name.lastIndexOf('.');
      const _imgType = _name.substring(_index);
      if (
        _imgType == '.jpg' ||
        _imgType == '.jpeg' ||
        _imgType == '.png' ||
        _imgType == '.PNG'
      ) {
        console.log(file);
      } else {
        this.$message.error(`请选择5M以内，JPG、JPEG和PNG格式图片`);
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isLt2M;
    },
    // 上传成功
    handleUploadSuccess(data) {
      if (data.status === 200) {
        this.qyrzForm.img = data.data[0].fileUrl;
      }
    },
    //文件超出限制个数提示
    handleExceed() {
      this.$message.warning('只需上传一张图片');
    },

    uploadVideoProcess(event, file) {
      this.$nextTick(() => {
        this.qyrzForm.img = URL.createObjectURL(file.raw);
      });
    },
    // 移除
    handleRemove() {
      this.qyrzForm.img = '';
    },
    // 地区
    handleChange() {
      var loc = '';
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
      this.qyrzForm.companyAddress = loc;
    }
  }
};
</script>

<style lang="less" scoped>
.EnterpriseCertification {
  // 标题
  &-title {
    margin-top: -40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  // 标题说明
  &-text {
    margin-top: 8px;
    text-align: center;
    font-size: 16px;
    color: #999999;
  }
  // 企业认证窗口
  &-qyrz {
    /deep/.el-dialog {
      width: 703px;
      height: 889px;
      overflow: hidden;
      position: absolute;
      left: 32%;
      &__header {
        border: none;
      }
    }
    &-border {
      margin: 30px -49px 0;
      width: 100% + 16;
      height: 1px;
      background: #e5e5e5;
    }
    &-border1 {
      margin: 30px -60px;
      width: 100% + 37;
      height: 1px;
      background: #e5e5e5;
    }
    &-border2 {
      margin: 234px -60px 0;
      width: 100%+37;
      height: 1px;
      background: #e5e5e5;
    }
    // 企业认证表单
    /deep/&-form {
      margin: 20px 80px 0 10px;
      .el-form-item {
        height: 34px;
        &__error {
          font-size: 12px;
          padding-top: 0;
        }
      }
      .el-input__inner {
        width: 362px;
        height: 36px;
        font-size: 16px;
        background: #ffffff;
        border: 1px solid #999999;
        border-radius: 4px;
      }
      .el-form-item__label {
        font-size: 18px;
        color: #333333;
      }
      &-img {
        display: flex;
        justify-content: space-between;
        width: 363px;
        .upload {
          display: flex;
          .el-upload-dragger {
            width: 221px;
            height: 146px;
            background: #ffffff;
            border: 1px solid #dcdcdc;
            border-radius: 4px;
          }
          .el-upload-list {
            margin: -20px 0 10px;
          }
          .UploadPic {
            max-width: 221px;
            max-height: 146px;
            padding: auto;
          }
          .img {
            width: 34px;
            height: 34px;
            margin-top: 33px;
            margin-bottom: -6px;
          }
          span {
            width: 128px;
            height: 16px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
          p {
            font-size: 16px;
            color: #999999;
            line-height: 1px;
          }
        }
        .example {
          img {
            width: 112px;
            height: 146px;
            border-radius: 4px;
          }
          p {
            margin-top: -15px;
            margin-left: 8px;
            font-size: 16px;
            color: #333333;
          }
        }
        &-requested {
          width: 363px;
          font-size: 16px;
          line-height: 18px;
          color: rgba(102, 102, 102, 1);
          font-weight: 400;
          font-family: Microsoft YaHei;
          span {
            color: #333333;
          }
        }
        &-requested1 {
          margin-top: 14px;
        }
      }
      &-btn {
        display: flex;
        justify-content: space-between;
        width: 362px;
      }
    }

    .btn-reelect,
    .btn-submit {
      margin-top: 30px;
      width: 160px;
      height: 40px;
      border: none;
      font-size: 16px;
    }
    .btn-reelect {
      border: 1px solid #999999;
      background: #ffffff;
      color: #999999;
    }
    .btn-submit {
      border: 1px solid #0096ff;
      background: #0096ff;
      color: #ffffff;
    }
  }
}

/*去除upload组件过渡效果*/
::v-deep .el-upload-list__item {
  transition: none !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1366fc;
}
</style>
