import Vue from 'vue';
import {
  ColorPicker,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Dialog,
  Upload,
  Tooltip,
  Row,
  Col,
  Select,
  Option,
  Steps,
  Step,
  Container,
  Header,
  Aside,
  Main,
  Radio,
  RadioGroup,
  RadioButton,
  Table,
  TableColumn,
  Button,
  Message,
  MessageBox,
  Form,
  FormItem,
  Input,
  Checkbox,
  CheckboxGroup,
  CheckboxButton,
  TabPane,
  Tabs,
  Slider,
  Tree,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Cascader,
  Pagination,
  Loading,
  Badge,
  DatePicker,
  Popover,
  InputNumber,
  InfiniteScroll
} from 'element-ui';

//全局修改默认配置
// 点击空白处不能关闭弹窗
Dialog.props.closeOnClickModal.default = false;
// 遮罩层会插入至 Dialog 的父元素上
Dialog.props.modalAppendToBody.default = false;
Vue.use(Container)
  .use(InputNumber)
  .use(ColorPicker)
  .use(Popover)
  .use(Tree)
  .use(Progress)
  .use(Dropdown)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Dialog)
  .use(Upload)
  .use(Tooltip)
  .use(Row)
  .use(Col)
  .use(Option)
  .use(Select)
  .use(Steps)
  .use(Step)
  .use(Form)
  .use(FormItem)
  .use(Input)
  .use(Checkbox)
  .use(CheckboxButton)
  .use(CheckboxGroup)
  .use(Header)
  .use(Aside)
  .use(Main)
  .use(Radio)
  .use(RadioGroup)
  .use(RadioButton)
  .use(Table)
  .use(Button)
  .use(TableColumn)
  .use(Tabs)
  .use(TabPane)
  .use(Slider)
  .use(Menu)
  .use(Submenu)
  .use(MenuItem)
  .use(MenuItemGroup)
  .use(Cascader)
  .use(Pagination)
  .use(Loading)
  .use(InfiniteScroll)
  .use(Badge)
  .use(DatePicker);

Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
