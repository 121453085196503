import Vue from 'vue';
import VueRouter from 'vue-router';
import Page from '../views/Page.vue';

Vue.use(VueRouter);

const routes = [
  // 登录页面
  {
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  // 首页
  {
    path: '/',
    component: Page,
    children: [
      {
        path: '/',
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/Home.vue')
      },
      // 私有化部署
      {
        path: '/deployGP',
        component: () =>
          import(/* webpackChunkName: "home" */ '../views/DeployGP.vue')
      },
      // 服务升级
      {
        path: '/serviceUpgrade',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/ServiceUpgrade/ServiceUpgrade.vue'
          )
      },
      // 智慧水务
      {
        path: '/zhswDetail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/scene/zhsw/ZhswDetail.vue'
          )
      },
      // 智慧警务
      {
        path: '/zhjwDetail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/scene/zhjw/ZhjwDetail.vue'
          )
      },
      // 智慧城管
      {
        path: '/zhcgDetail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/scene/zhcg/ZhcgDetail.vue'
          )
      },
      // 智慧交通
      {
        path: '/zhjtDetail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/scene/zhjt/ZhjtDetail.vue'
          )
      },
      // 智慧应急
      {
        path: '/zhyjDetail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/scene/zhyj/ZhyjDetail.vue'
          )
      },
      // 智慧园区
      {
        path: '/zhyqDetail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/scene/zhyq/ZhyqDetail.vue'
          )
      },
      // 智慧文旅
      {
        path: '/zhwlDetail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/scene/zhwl/ZhwlDetail.vue'
          )
      },
      // 智慧国土
      {
        path: '/zhgtDetail',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/scene/zhgt/ZhgtDetail.vue'
          )
      }
    ]
  },
  // 工作台
  {
    path: '/workbench',
    component: () =>
      import(
        /* webpackChunkName: "workbench" */ '../views/workbench/Workbench.vue'
      ),
    children: [
      // 地图
      {
        path: 'map',
        component: () =>
          import(
            /* webpackChunkName: "map" */ '../views/workbench/MapPage.vue'
          ),
        children: [
          {
            path: 'layerManager',
            component: () =>
              import(
                /* webpackChunkName: "layerManager" */ '../views/workbench/layerManager/LayerManager.vue'
              )
          },
          {
            path: 'dataManager',
            component: () =>
              import(
                /* webpackChunkName: "dataManager" */ '../views/workbench/dataManager/DataManager.vue'
              )
          },
          {
            path: 'materialLibrary',
            component: () =>
              import(
                /* webpackChunkName: "materialLibrary" */ '../views/workbench/materialLibrary/MaterialLibrary.vue'
              ),
            children: [
              {
                path: 'iconLibrary',
                component: () =>
                  import(
                    /* webpackChunkName: "iconLibrary" */ '../views/workbench/materialLibrary/IconLibrary.vue'
                  )
              },
              {
                path: 'polylineLibrary',
                component: () =>
                  import(
                    /* webpackChunkName: "polylineLibrary" */ '../views/workbench/materialLibrary/PolylineLibrary.vue'
                  )
              },
              {
                path: 'planeLibrary',
                component: () =>
                  import(
                    /* webpackChunkName: "planeLibrary" */ '../views/workbench/materialLibrary/PlaneLibrary.vue'
                  )
              },
              {
                path: 'imageLibrary',
                component: () =>
                  import(
                    /* webpackChunkName: "imageLibrary" */ '../views/workbench/materialLibrary/ImageLibrary.vue'
                  )
              },
              {
                path: 'styleLibrary',
                component: () =>
                  import(
                    /* webpackChunkName: "styleLibrary" */ '../views/workbench/materialLibrary/StyleLibrary.vue'
                  )
              }
            ]
          },
          // 设置
          {
            path: 'setting',
            component: () =>
              import(
                /* webpackChunkName: "setting" */ '../views/workbench/map/MapSetting.vue'
              )
          }
        ]
      },
      // 工作空间
      {
        path: 'workspace',
        component: () =>
          import(
            /* webpackChunkName: "workspace" */ '../views/workbench/Workspace.vue'
          )
      },
      // 我的服务
      {
        path: 'myService',
        component: () =>
          import(
            /* webpackChunkName: "myService" */ '../views/workbench/MyService.vue'
          )
      },
      // 发布服务
      {
        path: 'releaseService',
        component: () =>
          import(
            /* webpackChunkName: "releaseService" */ '../views/workbench/releaseService/ReleaseService.vue'
          )
      },
      // 从图层发布服务
      {
        path: 'releaseService/fromLayer',
        name: 'ReleaseServiceFromLayer',
        component: () =>
          import(
            /* webpackChunkName: "releaseServiceFromLayer" */ '../views/workbench/releaseService/ReleaseServiceFromLayer.vue'
          )
      },
      // 创建新的服务
      {
        path: 'releaseService/new',
        prop: true,
        component: () =>
          import(
            /* webpackChunkName: "releaseNewService" */ '../views/workbench/releaseService/ReleaseNewService.vue'
          )
      },
      // 发布服务成功
      {
        path: 'releaseService/success',
        prop: true,
        component: () =>
          import(
            /* webpackChunkName: "releaseServiceSuccess" */ '../views/workbench/releaseService/ReleaseServiceSuccess.vue'
          )
      },
      // 文件管理
      {
        path: 'fileManager',
        component: () =>
          import(
            /* webpackChunkName: "fileManager" */ '../views/workbench/FileManager.vue'
          )
      },
      // 购买记录
      {
        path: 'buyRecord',
        component: () =>
          import(
            /* webpackChunkName: "BuyRecord" */ '../views/workbench/BuyRecord.vue'
          )
      },
      // 账号信息
      {
        path: 'account/info',
        component: () =>
          import(
            /* webpackChunkName: "AccountInfo" */ '../views/workbench/account/AccountInfo.vue'
          )
      },
      // 消息中心
      {
        path: 'account/message',
        component: () =>
          import(
            /* webpackChunkName: "Message" */ '../views/workbench/account/Message.vue'
          )
      },
      // 我的券包
      {
        path: 'account/couponPack',
        component: () =>
          import(
            /* webpackChunkName: "CouponPack" */ '../views/workbench/account/CouponPack.vue'
          )
      },
      // 消息详情
      {
        path: 'account/messageDetail/:id',
        component: () =>
          import(
            /* webpackChunkName: "MessageDetail" */ '../views/workbench/account/MessageDetail.vue'
          )
      },
      // 开发者认证
      {
        path: 'account/authentication',
        component: () =>
          import(
            /* webpackChunkName: "Authentication" */ '../views/workbench/account/Authentication.vue'
          )
      }
    ]
  },
  // 微信支付
  {
    path: '/wxPay(/:orderId/:orderName/:codeUrl/:money)?',
    name: 'WxPay',
    props: true,
    component: () =>
      import(/* webpackChunkName: "wxPay" */ '../views/WxPay.vue')
  }
];

const router = new VueRouter({
  routes
});
// 跳转路由时默认回到页面顶部
router.beforeEach((to, from, next) => {
  if (to.path === '/onlineDoc') {
    // 跳转到在线文档
    window.open(window.onlineDocPage, 'onlineDocPage');
    return false;
  }
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  next();
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};

export default router;
