import api from './modules/api';
import data from './modules/data';
import layer from './modules/layer';
import login from './modules/login';
import file from './modules/file';
import feature from './modules/feature';
import materialLibrary from './modules/materialLibrary';
import workspace from './modules/workspace';
import quota from './modules/quota';
import payment from './modules/payment';
import giss from './modules/giss';

export default {
  giss,
  payment,
  quota,
  feature,
  api,
  data,
  layer,
  login,
  file,
  materialLibrary,
  workspace
};
