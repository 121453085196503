<template>
  <button class="btn" type="button" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'Button'
};
</script>

<style lang="less" scoped>
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 127px;
  height: 37px;
  background: #0096ff;
  border-radius: 8px;
  color: #fff;
  font-size: 20px;
  &:active {
    filter: brightness(120%);
  }
}
</style>
