// 数据相关
const state = {
  // 导入数据弹窗状态
  datasetImportDataDialogVisible: false,
  // 新建数据集弹窗状态
  addDatasetDialogVisible: false,
  // 新建数据类弹窗状态
  addDataclassDialogVisible: '',
  // 数据集属性弹窗状态
  datasetAttrDialogVisible: false,
  // 数据类属性弹窗状态
  dataclassAttrDialogVisible: {},
  // 数据类属性数据
  dataclassAttrData: [],
  // 要素类属性样式编辑
  dataclassAttrSytleEditDialog: {
    // status: true
  },
  // 数据类属性表弹窗状态
  dataclassAttrSheetPanelVisible: {},
  // 数据类属性字段编辑
  dataclassAttrFieldDialogVisible: {},
  // 在线服务弹窗
  onlineServiceDialogVisible: false,
  // 栅格数据属性表
  gridDataclassAttrDialogVisible: false,
  // 添加数据集
  addDatasetId: '',
  // 复制数据集
  datasetCopyId: '',
  // 导入要素类数据弹窗状态
  importDataFeatureClassDialogVisible: false,
  // 数据类地图展示数据集合
  dataClassFeaturesData: {},
  // 数据类地图已展示数据ID组
  dataClassFeaturesKeys: [],
  // 数据类数据编辑
  dataClassDataEdit: {},
  // 视频数据类数据
  dataClassVideoData: {},
  // 新建、编辑视频数据时地图图标
  videoDataMark: '',
  // 数据管理地图数据是否需要更新
  dataNeedUpdate: []
};

const mutations = {
  // 退出数据管理时，恢复初始状态
  quitDataManager(state) {
    state.dataNeedUpdate = [];
    state.datasetImportDataDialogVisible = false;
    state.addDatasetDialogVisible = false;
    state.addDataclassDialogVisible = false;
    state.datasetAttrDialogVisible = false;
    state.dataclassAttrDialogVisible = false;
    state.dataclassAttrData = [];
    state.dataclassAttrSytleEditDialog = {};
    state.dataclassAttrSheetPanelVisible = false;
    state.dataAttrSheetData = '';
    state.dataclassAttrFieldDialogVisible = false;
    state.onlineServiceDialogVisible = false;
    state.gridDataclassAttrDialogVisible = false;
    state.addDatasetId = false;
    state.datasetCopyId = false;
    state.importDataFeatureClassDialogVisible = false;
    state.dataClassFeaturesData = {};
    state.dataClassVideoData = {};
    state.dataClassDataEdit = {};
    state.videoDataMark = '';
    state.dataClassFeaturesKeys = [];
  },
  setDataNeedUpdate(state, val) {
    state.dataNeedUpdate = val;
  },
  setDataClassFeaturesData(state, data) {
    state.dataClassFeaturesData[data.key] = data.val;
  },
  setDataClassFeaturesKeys(state, data) {
    state.dataClassFeaturesKeys = data.val;
  },
  setGridDataclassAttrDialogVisible(state, val) {
    state.gridDataclassAttrDialogVisible = val;
  },
  setOnlineServiceDialogVisible(state, val) {
    state.onlineServiceDialogVisible = val;
  },
  setDatasetImportDataDialogVisible(state, val) {
    state.datasetImportDataDialogVisible = val;
  },
  setDataAttrSheetData(state, val) {
    state.dataAttrSheetData = val;
  },
  setDataclassAttrSytleEditDialog(state, val) {
    state.dataclassAttrSytleEditDialog = val;
  },
  setDataclassAttrFieldDialogVisible(state, val) {
    state.dataclassAttrFieldDialogVisible = val;
  },
  setDatasetAttrDialogVisible(state, val) {
    state.datasetAttrDialogVisible = val;
  },
  setDataclassAttrDialogVisible(state, val) {
    state.dataclassAttrDialogVisible = val;
  },
  setDataclassAttrData(state, val) {
    state.dataclassAttrData = val;
  },
  setDataclassAttrSheetPanelVisible(state, val) {
    state.dataclassAttrSheetPanelVisible = val;
  },
  setAddDatasetDialogVisible(state, val) {
    state.addDatasetDialogVisible = val;
  },
  setAddDataclassDialogVisible(state, val) {
    state.addDataclassDialogVisible = val;
  },
  setAddDatasetId(state, val) {
    state.addDatasetId = val;
  },
  setDatasetCopyId(state, val) {
    state.datasetCopyId = val;
  },

  setDataClassDataEdit(state, val) {
    state.dataClassDataEdit = val;
  },
  setImportDataFeatureClassDialogVisible(state, val) {
    state.importDataFeatureClassDialogVisible = val;
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
