// 通用
const state = {
  // 右键菜单数据
  contextMenuData: [],
  // 右键菜单数据
  contextMenuPosition: {},
  // 右键菜单可见状态
  contextMenuVisible: false,
  // 右键点击项数据
  contextClickData: '',
  // 编辑文本id
  editTextId: '',
  // 当前工作空间
  workspaceId: '',
  // 删除
  delData: '',
  // 关于我们
  aboutUsDialogVisible: false
};

const mutations = {
  setWorkspaceId(state, val) {
    state.workspaceId = val;
  },
  setFileTextId(state, val) {
    state.fileTextId = val;
  },
  setEditTextId(state, val) {
    state.editTextId = val;
  },
  setContextClickData(state, val) {
    state.contextClickData = val;
  },
  setContextMenuData(state, val) {
    state.contextMenuData = val;
  },
  setContextMenuPosition(state, val) {
    state.contextMenuPosition = val;
  },
  setContextMenuVisible(state, val) {
    state.contextMenuVisible = val;
  },
  setDelDataId(state, val) {
    state.delData = val;
  },
  setAboutUsDialogVisible(state, val) {
    state.aboutUsDialogVisible = val;
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
