import axios from 'p/axios';
import qs from 'qs';
/* eslint-disable */
// 添加分类接口
const styleResourceCategoryAdd = data => {
  return axios.post('/style/resource/category/add', qs.stringify(data))
}

// 获取全部分类接口
const styleResourceCategoryAll = data => {
  return axios.get('/style/resource/category/all', { params: data })
}

// 样式分类排序接口
const styleResourceCategoryOrder = data => {
  return axios.post('/style/resource/category/order', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 修改分类名称接口
const styleResourceCategoryModifyName = data => {
  return axios.post('/style/resource/category/modifyName', qs.stringify(data))
}

// 删除分类接口
const styleResourceCategoryDel = data => {
  return axios.post('/style/resource/category/del', qs.stringify(data))
}


// 图标
// 添加图标
const styleIconAdd = data => {
  return axios.post('/style/icon/add', qs.stringify(data))
}

// 分页查询图标
const styleIconList = data => {
  return axios.get('/style/icon/list', { params: data })
}

// 移动到新分类接口
const styleIconMove = data => {
  return axios.post('/style/icon/move', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 删除图标
const styleIconDel = data => {
  return axios.post('/style/icon/del', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 批量取消收藏
const styleIconCollectionDelBatch = data => {
  return axios.post('/style/icon/collection/del/batch', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 编辑图标
const styleIconEidt = data => {
  return axios.post('/style/icon/edit', qs.stringify(data))
}

// 收藏图标
const styleIconCollection = data => {
  return axios.post('/style/icon/collection', qs.stringify(data))
}

// 取消收藏
const styleIconCollectionDel = data => {
  return axios.post('/style/icon/collection/del', qs.stringify(data))
}

// 我的收藏
const styleIconCollectionList = data => {
  return axios.get('/style/icon/collection/list', { params: data })
}

// 移动收藏到新分类
const styleIconCollectionMove = data => {
  return axios.post('/style/icon/collection/move', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}



// 线段
// 添加线段
const styleLineAdd = data => {
  return axios.post('/style/line/add', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 删除线段
const styleLineDel = data => {
  return axios.post('/style/line/del', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 删除线段
const styleLineDelBatch = data => {
  return axios.post('/style/line/del/batch', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 分页查询线段
const styleLineList = data => {
  return axios.get('/style/line/list', { params: data })
}

// 编辑线段
const styleLineEidt = data => {
  return axios.post('/style/line/edit', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 移动线段分类
const styleLineMove = data => {
  return axios.post('/style/line/move', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 我的收藏
const styleLineCollectionList = data => {
  return axios.get('/style/line/collection/list', { params: data })
}

// 收藏线段
const styleLineCollection = data => {
  return axios.post('/style/line/collection', qs.stringify(data))
}

// 批量取消收藏
const styleLineCollectionDelBatch = data => {
  return axios.post('/style/line/collection/del/batch', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 移动收藏到新分类
const styleLineCollectionMove = data => {
  return axios.post('/style/line/collection/move', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}


// 多边形
// 添加多边形
const stylePolygonAdd = data => {
  return axios.post('/style/polygon/add', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 删除多边形
const stylePolygonDel = data => {
  return axios.post('/style/polygon/del/batch', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 分页查询多边形
const stylePolygonList = data => {
  return axios.get('/style/polygon/list', { params: data })
}

// 编辑多边形
const stylePolygonEidt = data => {
  return axios.post('/style/polygon/edit', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 移动多边形分类
const stylePolygonMove = data => {
  return axios.post('/style/polygon/move', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 我的收藏
const stylePolygonCollectionList = data => {
  return axios.get('/style/polygon/collection/list', { params: data })
}

// 收藏多边形
const stylePolygonCollection = data => {
  return axios.post('/style/polygon/collection', qs.stringify(data))
}

// 批量取消收藏
const stylePolygonCollectionDelBatch = data => {
  return axios.post('/style/polygon/collection/del/batch', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 移动收藏到新分类
const stylePolygonCollectionMove = data => {
  return axios.post('/style/polygon/collection/move', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}


// 样式
// 添加样式
const styleResourceAdd = data => {
  return axios.post('/style/resource/add', qs.stringify(data))
}

// 删除样式
const styleResourceDel = data => {
  return axios.post('/style/resource/del', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 分页查询样式
const styleResourceList = data => {
  return axios.get('/style/resource/list', { params: data })
}

// 编辑样式
const styleResourceEidt = data => {
  return axios.post('/style/resource/edit', qs.stringify(data))
}

// 移动样式分类
const styleResourceMove = data => {
  return axios.post('/style/resource/move', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 我的收藏
const styleResourceCollectionList = data => {
  return axios.get('/style/resource/collection/list', { params: data })
}

// 收藏样式
const styleResourceCollection = data => {
  return axios.post('/style/resource/collection', qs.stringify(data))
}

// 批量取消收藏
const styleResourceCollectionDelBatch = data => {
  return axios.post('/style/resource/collection/del/batch', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

// 移动收藏到新分类
const styleResourceCollectionMove = data => {
  return axios.post('/style/resource/collection/move', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}


// 最近使用-点
const styleIconLately = data => {
  return axios.get('/style/icon/lately', { params: data })
}

// 最近使用-线
const styleIineLately = data => {
  return axios.get('/style/line/lately', { params: data })
}

// 最近使用-面
const stylePolygonLately = data => {
  return axios.get('/style/polygon/lately', { params: data })
}


export default {
  styleResourceCategoryAdd,
  styleResourceCategoryAll,
  styleResourceCategoryOrder,
  styleResourceCategoryModifyName,
  styleResourceCategoryDel,
  styleIconAdd,
  styleIconList,
  styleIconMove,
  styleIconDel,
  styleIconEidt,
  styleIconCollection,
  styleIconCollectionDel,
  styleIconCollectionList,
  styleIconCollectionDelBatch,
  styleIconCollectionMove,
  styleLineAdd,
  styleLineDel,
  styleLineDelBatch,
  styleLineList,
  styleLineEidt,
  styleLineMove,
  styleLineCollectionList,
  styleLineCollection,
  styleLineCollectionDelBatch,
  styleLineCollectionMove,
  stylePolygonAdd,
  stylePolygonDel,
  stylePolygonList,
  stylePolygonEidt,
  stylePolygonMove,
  stylePolygonCollectionList,
  stylePolygonCollection,
  stylePolygonCollectionDelBatch,
  stylePolygonCollectionMove,
  styleResourceAdd,
  styleResourceDel,
  styleResourceList,
  styleResourceEidt,
  styleResourceMove,
  styleResourceCollectionList,
  styleResourceCollection,
  styleResourceCollectionDelBatch,
  styleResourceCollectionMove,
  styleIconLately,
  styleIineLately,
  stylePolygonLately,
};