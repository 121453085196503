import axios from 'p/axios';
import qs from 'qs';
/* eslint-disable */
// 发送验证码
const sendCode = data => {
  return axios.post('auth/sms/sendCode', qs.stringify(data), { baseURL: loginIp })
}

// 登录/注册
const login = data => {
  return axios.post('/auth/login/sms', qs.stringify(data), { baseURL: loginIp })
}

// 登录成功后的重定向
const redirect = data => {
  return axios.get('/cas/redirect', {
    params: data,
    baseURL: loginIp
  })
}

// 子系统code换token
const getToken = data => {
  return axios.post('/cas/token/get', qs.stringify(data), { baseURL: loginIp })
}

export default { sendCode, login, redirect, getToken };