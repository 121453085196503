<template>
  <el-dialog
    class="notice-dialog"
    :visible="true"
    :lock-scroll="false"
    @close="$emit('close')"
  >
    <div class="wrap">
      <div class="wrap-bg"></div>
      <i class="icon icon-attrSheet-close closeBtn" @click="$emit('close')"></i>
      <img class="img" src="@/assets/hj.png" alt="" />
      <div class="wrap-text">
        <p class="title">Longmap平台提示</p>
        <p>
          Longmap平台试用期已结束，如果需要体验完整版应用，请致电：<span
            style="color: #3676f5"
            >19926633862</span
          >
        </p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'NoticeDialog'
};
</script>

<style lang="less">
.notice-dialog {
  .el-dialog {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    height: 240px;
    margin: 0 !important;
    border-radius: 12px;
    &__header {
      display: none;
    }
    &__body {
      padding: 0;
      height: 100%;
    }
  }
  .wrap {
    position: relative;
    height: 100%;
    .closeBtn {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;
    }
    &-bg {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 120px;
      border-radius: 12px;
      background: linear-gradient(
        180deg,
        rgba(204, 221, 255, 1) 0%,
        rgba(204, 221, 255, 0) 100%
      );
    }
    .img {
      position: absolute;
      z-index: -1;
      top: -32px;
      left: 0;
    }
    &-text {
      padding-top: 45px;
      padding-left: 118px;
      padding-right: 74px;
      font-size: 18px;
      color: #666;
      .title {
        margin-bottom: 40px;
        color: #333;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
}
</style>
