// 数据相关
const state = {
  // 新建图层弹窗状态
  addLayerDataDialogVisible: false,
  // 添加图层数据弹窗状态
  layerAddDataDialogVisible: {},
  // 更新服务弹窗状态
  layerUpdateServiceDialogVisible: false,
  // 服务发布弹窗状态
  layerPublishingServiceDialogVisible: false,
  // 图层属性
  layerAttrDialogVisible: {
    // status: true
  },
  // 数据类属性
  layerClassAttrDialogVisible: {
    // status: true
  },
  // 数据类属性表弹窗状态
  layerAttrSheetDialogVisible: false,
  // 属性表数据
  layerAttrSheetData: '',
  // 属性字段编辑
  layerAttrFieldDialogVisible: false,
  // 添加图层
  addLayerId: '',
  // 图层管理地图展示数据集合
  layerManagerData: {},
  // 图层管理地图数据显示状态集合
  layerDataFeaturesKey: {},
  // 树形图层数据
  layerTreeData: [],
  // 数据管理地图数据是否需要更新
  layerDataNeedUpdate: []
};

const mutations = {
  // 退出图层管理时，恢复初始状态
  quitLayerManager(state) {
    state.layerDataNeedUpdate = [];
    state.layerManagerData = {};
    state.addLayerDataDialogVisible = false;
    state.layerAddDataDialogVisible = false;
    state.layerUpdateServiceDialogVisible = false;
    state.layerPublishingServiceDialogVisible = false;
    state.layerClassAttrDialogVisible = false;
    state.layerAttrDialogVisible = false;
    state.layerAttrSheetDialogVisible = false;
    state.layerAttrSheetData = '';
    state.layerAttrFieldDialogVisible = false;
    state.addLayerId = '';
    state.layerTreeData = [];
    state.layerDataFeaturesKey = {};
  },
  setLayerDataNeedUpdate(state, val) {
    state.layerDataNeedUpdate = val;
  },
  setLayerManagerData(state, data) {
    state.layerManagerData[data.key] = data.val;
  },
  setLayerDataFeaturesKey(state, data) {
    state.layerDataFeaturesKey[data.key] = data.val;
  },
  setLayerTreeData(state, val) {
    state.layerTreeData = val;
  },
  setAddLayerDataDialogVisible(state, val) {
    state.addLayerDataDialogVisible = val;
  },
  setLayerAddDataDialogVisible(state, val) {
    state.layerAddDataDialogVisible = val;
  },
  setLayerUpdateServiceDialogVisible(state, val) {
    state.layerUpdateServiceDialogVisible = val;
  },
  setLayerPublishingServiceDialogVisible(state, val) {
    state.layerPublishingServiceDialogVisible = val;
  },
  setLayerAttrDialogVisible(state, val) {
    state.layerAttrDialogVisible = val;
  },
  setLayerClassAttrDialogVisible(state, val) {
    state.layerClassAttrDialogVisible = val;
  },
  setLayerAttrSheetDialogVisible(state, val) {
    state.layerAttrSheetDialogVisible = val;
  },
  setLayerAttrSheetData(state, val) {
    state.layerAttrSheetData = val;
  },
  setLayerAttrFieldDialogVisible(state, val) {
    state.layerAttrFieldDialogVisible = val;
  },
  setAddLayerId(state, val) {
    state.addLayerId = val;
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
