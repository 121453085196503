import Vue from 'vue';
import $bus from 'p/utils';
// 自定义指令

// 输入框自动聚焦
Vue.directive('focus', {
  // 当被绑定的元素插入到DOM中时
  inserted: function (el) {
    // 聚焦元素
    el.focus();
  }
});

// 输入框只能输入数字
Vue.directive(
  'number',
  // 第一次绑定(bind)和属性更新(update)时触发
  function (el, binding) {
    el.value = String(binding.value).replace(/[^0-9.]/g, '');
  }
);

// 拖动
Vue.directive('drag', {
  // 插入后，要不然获取不到样式
  inserted(el) {
    // 控制拖动的元素
    const dragHandle = el.querySelector('[data-drag="dragHandle"]');
    // 统一以left定位为准，right定位的计算出对应left值
    const style = window.getComputedStyle(el, null);
    el.style.width = style.width;
    el.style.height = style.height;
    el.style.left = style.left;
    el.style.right = 'initial';
    el.style.top = style.top;
    el.style.bottom = 'initial';
    // 判断边界
    let attractRange = 10; // 拖动时判断边界，自动吸引靠边
    let judgeTop = 0; // 拖动、缩放时，上边界
    let judgeBottom = 0; // 拖动时下边界
    let scaleBorderRange = 10; // 鼠标在面板四周可缩放范围
    // 页面尺寸
    let pageWidth = 0;
    let pageHeight = 0;
    // 面板坐标和尺寸
    let panelX = 0;
    let panelY = 0;
    let panelWidth = 0;
    let panelHeight = 0;
    // 拖动状态、初始位置
    let dragState = false;
    let x = 0;
    let y = 0;
    // 缩放状态、类型
    let scaleType = 0;
    let scaleState = false;
    // 鼠标按下时开始拖动
    dragHandle.onmousedown = function (e) {
      // 开启拖动
      dragState = true;
      const style = window.getComputedStyle(el, null);
      // 页面尺寸
      pageWidth = document.querySelector('.mapPage').clientWidth;
      pageHeight = document.querySelector('.mapPage').clientHeight;
      // 初始化默认位置
      x = e.pageX;
      y = e.pageY;
      // 面板坐标和尺寸
      panelX = parseFloat(style.left);
      panelY = parseFloat(style.top);
      panelWidth = parseFloat(style.width);
      panelHeight = parseFloat(style.height);
      // 上边界判断条件
      judgeTop =
        (document.querySelector('.mytitle')
          ? document.querySelector('.mytitle').offsetHeight
          : 0) +
        (document.querySelector('.menu')
          ? document.querySelector('.menu').offsetHeight
          : 0) +
        (document.querySelector('.tool')
          ? document.querySelector('.tool').offsetHeight
          : 0);
    };
    // 鼠标移动时，更新面板位置、尺寸
    // addEventListener添加监听，避免重复事件覆盖之前的时间
    document.addEventListener('mousemove', function (e) {
      const mx = e.pageX - x;
      const my = e.pageY - y;
      // 拖动，更新位置
      if (dragState && !scaleState) {
        let left = panelX + mx;
        let top = panelY + my;
        // 判断左边界
        if (left < attractRange) {
          left = 0;
        }
        // 判断右边界
        if (pageWidth - panelWidth - left < attractRange) {
          left = pageWidth - panelWidth;
        }
        // 判断上边界
        if (top - judgeTop < attractRange) {
          top = judgeTop;
        }
        // 判断下边界
        if (pageHeight - panelHeight - judgeBottom - top < attractRange) {
          top = pageHeight - panelHeight - judgeBottom;
        }
        el.style.left = left + 'px';
        el.style.top = top + 'px';
      }
      // 缩放，更新尺寸
      if (scaleState && !dragState) {
        // 左
        if (scaleType === 1 || scaleType === 2 || scaleType === 3) {
          let left = panelX + mx;
          let width = panelWidth - mx;
          // 判断是否超出左边界
          if (left < 0) {
            left = 0;
            width = panelWidth + panelX;
          }
          // 修改left和width
          el.style.left = left + 'px';
          el.style.width = width + 'px';
        }
        // 右
        if (scaleType === 4 || scaleType === 5 || scaleType === 6) {
          let width = panelWidth + mx;
          // 判断是否超出右边界
          if (width + panelX > pageWidth) {
            width = pageWidth - panelX;
          }
          // 修改width
          el.style.width = width + 'px';
        }
        // 上
        if (scaleType === 2 || scaleType === 5 || scaleType === 7) {
          let top = panelY + my;
          let height = panelHeight - my;
          if (top < judgeTop) {
            top = judgeTop;
            height = panelHeight + panelY - judgeTop;
          }
          // 修改top和height
          el.style.top = top + 'px';
          el.style.height = height + 'px';
        }
        // 下
        if (scaleType === 3 || scaleType === 6 || scaleType === 8) {
          // 判断是否超出下边界
          let height = panelHeight + my;
          if (height + panelY > pageHeight) {
            height = pageHeight - panelY;
          }
          // 修改height
          el.style.height = height + 'px';
        }
      }
    });
    // 鼠标松开时，关闭拖动、缩放
    document.addEventListener('mouseup', function () {
      // 关闭拖动
      dragState = false;
      // 关闭缩放
      scaleState = false;
      scaleType = 0;
      // 恢复指针样式
      document.body.style.cursor = 'default';

      // 修改元素样式
      // let panelX = parseFloat(style.left);
      // let panelY = parseFloat(style.top);
      // let panelWidth = parseFloat(style.width);
      // let panelHeight = parseFloat(style.height);
      // el.style.right = window.innerWidth - panelX - panelWidth + 'px';
      // el.style.bottom = window.innerHeight - panelY - panelHeight + 'px';
    });

    // 鼠标在面板四周移动时，修改鼠标样式
    el.onmousemove = function (e) {
      // 缩放更新要素属性表高度
      if (scaleState) {
        $bus.$emit('dragArrtTableMaxHeight');
      }
      // 避免内部元素触发
      if (e.target.dataset.drag !== 'drag') return;
      // 当面板最小化时，禁止缩放
      if (el.getAttribute('class').indexOf('minimum') !== -1) return;
      // 在拖动或缩放过程中返回
      if (dragState || scaleState) return;
      const style = window.getComputedStyle(el, null);
      // 鼠标相对面板坐标
      const offsetX = e.offsetX;
      const offsetY = e.offsetY;
      // 面板尺寸
      const width = parseFloat(style.width);
      const height = parseFloat(style.height);
      let mouseXPosition = '';
      let mouseYPosition = '';
      // 移动到四周时，修改鼠标样式，并开启鼠标控制缩放
      if (offsetX < scaleBorderRange) {
        mouseXPosition = 'left';
      }
      if (width - offsetX < scaleBorderRange) {
        mouseXPosition = 'right';
      }
      if (offsetY < scaleBorderRange) {
        mouseYPosition = 'top';
      }
      if (height - offsetY < scaleBorderRange) {
        mouseYPosition = 'bottom';
      }
      // 修改鼠标样式、缩放方式
      let cursorType = 'default';
      switch (mouseXPosition) {
        // 左边
        case 'left':
          cursorType = 'w-resize';
          scaleType = 1;
          // 顶部
          if (mouseYPosition === 'top') {
            cursorType = 'se-resize';
            scaleType = 2;
          }
          // 底部
          if (mouseYPosition === 'bottom') {
            cursorType = 'ne-resize';
            scaleType = 3;
          }
          break;
        // 右边
        case 'right':
          cursorType = 'w-resize';
          scaleType = 4;
          // 顶部
          if (mouseYPosition === 'top') {
            cursorType = 'ne-resize';
            scaleType = 5;
          }
          // 底部
          if (mouseYPosition === 'bottom') {
            cursorType = 'se-resize';
            scaleType = 6;
          }
          break;
        // 中间
        default:
          // 顶部
          if (mouseYPosition === 'top') {
            cursorType = 's-resize';
            scaleType = 7;
          }
          // 底部
          if (mouseYPosition === 'bottom') {
            cursorType = 's-resize';
            scaleType = 8;
          }
          break;
      }
      document.body.style.cursor = cursorType;
      if (cursorType === 'default') {
        scaleType = '';
      }
    };
    // 鼠标在四周按下时，确定缩放类型
    el.onmousedown = function (e) {
      if (scaleType && !dragState) {
        document.onselectstart = function () {
          return false;
        }; //解决拖动会选中文字的问题
        // 开启缩放
        scaleState = true;
        const style = window.getComputedStyle(el, null);
        pageWidth = window.innerWidth;
        pageHeight = window.innerHeight;
        // 初始化默认位置
        x = e.pageX;
        y = e.pageY;
        // 初始化面板坐标和尺寸
        panelX = parseFloat(style.left);
        panelY = parseFloat(style.top);
        panelWidth = parseFloat(style.width);
        panelHeight = parseFloat(style.height);
        // 上边界判断条件
        judgeTop =
          (document.querySelector('.mytitle')
            ? document.querySelector('.mytitle').offsetHeight
            : 0) +
          (document.querySelector('.menu')
            ? document.querySelector('.menu').offsetHeight
            : 0) +
          (document.querySelector('.tool')
            ? document.querySelector('.tool').offsetHeight
            : 0);
      }
    };

    // 鼠标离开面板时
    el.onmouseleave = function () {
      if (scaleState) return;
      // 恢复缩放状态和类型
      scaleState = false;
      scaleType = 0;
      // 恢复指针样式
      document.body.style.cursor = 'default';
    };
  }
});
