// 文件管理
const state = {
  // 文件传输进度数据
  fileTransList: [],
  // 传输面板toggle
  transferPanelToggle: false
};

const mutations = {
  setTransferPanelToggle(state, val) {
    state.transferPanelToggle = val;
  },
  setFileTransList(state, val) {
    state.fileTransList = val;
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
