// 地图基础相关数据
const state = {
  // 地图中心点
  center: window.defaultCenter,
  zoom: true,
  rotate: true,
  pan: true,
  enabled: true,
  duration: '10',
  // 底图数据
  providerData: {
    url: window.defaultProvider
  },
  flyToConfig: '', //定位配置
  // 是否全屏
  fullScreen: false,
  // 当前标绘uuid
  plotUuid: '',
  // 地图底部信息、工具栏可见状态
  mapLngVisible: true,
  mapLatVisible: true,
  mapHeadingVisible: true,
  mapPitchVisible: true,
  mapHeightVisible: true,
  mapDrawVisible: true,
  mapMeasureVisible: true,
  mapScaleRulerVisible: true,
  mapModelSwitchVisible: true,
  mapZoomVisible: true,
  mapCompassVisible: true,
  intercation: false // 表示地图是否正在交互中，禁止其他操作
};

const mutations = {
  setIntercation(state, val) {
    state.intercation = val;
  },
  setEnabled(state, val) {
    state.enabled = val;
  },
  setPan(state, val) {
    state.pan = val;
  },
  setRotate(state, val) {
    state.rotate = val;
  },
  setZoom(state, val) {
    state.zoom = val;
  },
  setMapCompassVisible(state, val) {
    state.mapCompassVisible = val;
  },
  setMapZoomVisible(state, val) {
    state.mapZoomVisible = val;
  },
  setMapModelSwitchVisible(state, val) {
    state.mapModelSwitchVisible = val;
  },
  setMapScaleRulerVisible(state, val) {
    state.mapScaleRulerVisible = val;
  },
  setMapMeasureVisible(state, val) {
    state.mapMeasureVisible = val;
  },
  setMapDrawVisible(state, val) {
    state.mapDrawVisible = val;
  },
  setMapHeightVisible(state, val) {
    state.mapHeightVisible = val;
  },
  setMapPitchVisible(state, val) {
    state.mapPitchVisible = val;
  },
  setMapHeadingVisible(state, val) {
    state.mapHeadingVisible = val;
  },
  setMapLatVisible(state, val) {
    state.mapLatVisible = val;
  },
  setMapLngVisible(state, val) {
    state.mapLngVisible = val;
  },
  setPlotUuid(state, val) {
    state.plotUuid = val;
  },
  setFullScreen(state, val) {
    state.fullScreen = val;
  },
  setCenter(state, val) {
    state.center = val;
  },
  setProviderData(state, val) {
    state.providerData = val;
  },
  setFlyToConfig(state, val) {
    state.center = val.compass;
  }
};
export default {
  namespaced: true,
  state,
  mutations
};
