import axios from 'p/axios_cl';
import qs from 'qs';

const config = {
  baseURL: window.paymentIp,
  paramsSerializer: {
    serialize(params) {
      // get请求传递数组
      return qs.stringify(params, { arrayFormat: 'repeat' });
    }
  }
};

// 订单支付
const payOrder = params =>
  axios.post('/pay/order', qs.stringify(params), { ...config });

export default {
  payOrder
};
