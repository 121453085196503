<template>
  <div>
    <div class="Shortcut">
      <!-- 电话 -->
      <el-tooltip
        class="item"
        effect="light"
        content="联系电话：0755-8633-6555"
        placement="left-start"
      >
        <div class="img Shortcut-phone"></div>
      </el-tooltip>
      <!-- 分割线 -->
      <!-- <div class="Shortcut-boder"></div> -->
      <!-- 意见反馈 -->
      <!-- <el-popover
        v-model="popoverVisible"
        trigger="click"
        effect="light"
        placement="left-start"
      >
        <div class="group">
          <el-button type="text" @click="handleGztjClick">故障提交</el-button>
          <div class="splitLine"></div>
          <el-button type="text" @click="handleCpjyClick">产品建议</el-button>
        </div>
        <template slot="reference"
          ><div class="img Shortcut-feedback"></div
        ></template>
      </el-popover> -->
    </div>
    <!-- 返回顶部 -->
    <div v-if="activeShow" class="goTop" ref="right" @click="goTopClick"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //默认关闭
      activeShow: false,
      popoverVisible: false
    };
  },
  methods: {
    handleCpjyClick() {
      this.$store.commit('userData/setProductSuggestionsDialogVisible', true);
      this.popoverVisible = false;
    },
    handleGztjClick() {
      this.$store.commit('userData/setCommitFailuresDialogVisible', true);
      this.popoverVisible = false;
    },
    goTopClick() {
      document.documentElement.scrollIntoView({ behavior: 'smooth' });
    },
    handleScroll() {
      let heightt =
        document.documentElement.scrollTop || document.body.scrollTop;

      if (heightt > 200) {
        this.activeShow = true;
      } else {
        this.activeShow = false;
      }
    }
  },
  mounted() {
    //监听滚动条
    window.addEventListener('scroll', this.handleScroll, true);
  }
};
</script>

<style lang="less" scoped>
.Shortcut {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 46px;
  padding: 10px 0;
  background: #ffffff;
  border-radius: 20px;
  position: fixed;
  right: 1%;
  bottom: 15%;
  box-shadow: 0px 0px 10px 3px #c7c5c534;
  /* 自定义内容的区域的颜色 */
  .el-tooltip__popper {
    width: 319px;
    min-height: 180px;
    background: #ffffff !important;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: none;
  }
  .img {
    cursor: pointer;
    width: 36px;
    height: 36px;
    background-repeat: no-repeat;
    background-position: center center;
  }
  &-phone {
    background-size: 20px 20px;
    background-image: url('../assets/icon-phone1.png');
    &:hover {
      background-size: 100%;
      background-image: url('../assets/icon-phone.png');
    }
  }
  &-feedback {
    background-size: 20px 20px;
    background-image: url('../assets/icon-feedback1.png');
    &:hover {
      background-size: 100%;
      background-image: url('../assets/icon-feedback2.png');
    }
  }
  &-boder {
    width: 30px;
    height: 1px;
    margin: 10px 0;
    background: #c5c5c5;
  }
}
.goTop {
  background-size: contain;
  position: fixed;
  width: 46px;
  height: 46px;
  background-image: url(../assets/goTop.png);
  cursor: pointer;
  z-index: 5;
  right: 1%;
  bottom: 7.5%;
}

.splitLine {
  height: 1px;
  margin: 5px 0;
  background: #eeeeee;
}
</style>
