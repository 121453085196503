import Vue from 'vue';
import Vuex from 'vuex';
import baseMap from './modules/baseMap';
import common from './modules/common';
import data from './modules/data';
import layerData from './modules/layerData';
import materialLibrary from './modules/materialLibrary';
import plot from './modules/plot';
import userData from './modules/userData';
import file from './modules/file';

Vue.use(Vuex);

const store = () =>
  new Vuex.Store({
    modules: {
      baseMap,
      common,
      data,
      layerData,
      materialLibrary,
      plot,
      userData,
      file
    }
  });

export default store;
