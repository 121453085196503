import axios from 'p/axios_cl';
import qs from 'qs';

// 数据类-获取样式
const featureClassStyle = params =>
  axios.get('/featureClass/style', { params });

// 点-新增
const pointAdd = params =>
  axios.post('/feature/point/add', qs.stringify(params));
// 点-修改
const pointEdit = params =>
  axios.post('/feature/point/edit', qs.stringify(params));
// 点-删除
const pointDel = params =>
  axios.post('/feature/point/del', qs.stringify(params));
// 点-详情
const pointGet = params => axios.get('/feature/point/get', { params });
// 点-分页加载
const pointPage = params =>
  axios.post('/feature/point/page', qs.stringify(params));
// 点-加载
const pointLoad = params => axios.get('/feature/point/load', { params });
// 点-属性表分页
const pointAttributeTable = params =>
  axios.post('/feature/point/attributeTable', qs.stringify(params));
// 点-设置样式
const pointStyleSet = params =>
  axios.post('/feature/point/style/set', qs.stringify(params));

// 线-新增
const lineAdd = params => axios.post('/feature/line/add', qs.stringify(params));
// 线-修改
const lineEdit = params =>
  axios.post('/feature/line/edit', qs.stringify(params));
// 线-删除
const lineDel = params => axios.post('/feature/line/del', qs.stringify(params));
// 线-详情
const lineGet = params => axios.get('/feature/line/get', { params });
// 线-分页加载
const linePage = params =>
  axios.post('/feature/line/page', qs.stringify(params));
// 线-加载
const lineLoad = params => axios.get('/feature/line/load', { params });
// 线-属性表分页
const lineAttributeTable = params =>
  axios.post('/feature/line/attributeTable', qs.stringify(params));
// 线-设置样式
const lineStyleSet = params =>
  axios.post('/feature/line/style/set', qs.stringify(params));

// 多边形-新增
const polygonAdd = params =>
  axios.post('/feature/polygon/add', qs.stringify(params));
// 多边形-修改
const polygonEdit = params =>
  axios.post('/feature/polygon/edit', qs.stringify(params));
// 多边形-删除
const polygonDel = params =>
  axios.post('/feature/polygon/del', qs.stringify(params));
// 多边形-详情
const polygonGet = params => axios.get('/feature/polygon/get', { params });
// 多边形-分页加载
const polygonPage = params =>
  axios.post('/feature/polygon/page', qs.stringify(params));
// 多边形-加载
const polygonLoad = params => axios.get('/feature/polygon/load', { params });
// 多边形-属性表分页
const polygonAttributeTable = params =>
  axios.post('/feature/polygon/attributeTable', qs.stringify(params));
// 多边形-设置样式
const polygonStyleSet = params =>
  axios.post('/feature/polygon/style/set', qs.stringify(params));

export default {
  featureClassStyle,
  polygonStyleSet,
  polygonAttributeTable,
  polygonLoad,
  polygonPage,
  polygonGet,
  polygonDel,
  polygonEdit,
  polygonAdd,
  lineStyleSet,
  lineAttributeTable,
  lineLoad,
  linePage,
  lineGet,
  lineDel,
  lineEdit,
  lineAdd,
  pointStyleSet,
  pointAttributeTable,
  pointLoad,
  pointPage,
  pointGet,
  pointDel,
  pointEdit,
  pointAdd
};
