// 标绘store
const state = {
  plotDialogType: false,
  iconLibraryDialogVisible: false,
  plotAddDatasetDialogVisible: false,
  plotAddDataclassDialogVisible: false
};

const mutations = {
  setPlotAddDataclassDialogVisible(state, val) {
    state.plotAddDataclassDialogVisible = val;
  },
  setPlotAddDatasetDialogVisible(state, val) {
    state.plotAddDatasetDialogVisible = val;
  },
  setIconLibraryDialogVisible(state, val) {
    state.iconLibraryDialogVisible = val;
  },
  setPlotDialogType(state, val) {
    state.plotDialogType = val;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
