import axios from 'p/axios';
import qs from 'qs';
/* eslint-disable */
// 工作空间创建
const workspaceCreate = data => {
  return axios.post('/workspace/create', qs.stringify(data))
}

// 工作空间查询所有
const workspaceGetAll = data => {
  return axios.get('/workspace/getAll', { params: data })
}

// 工作空间分页查询
const workspaceGetPage = data => {
  return axios.get('/workspace/getPage', { params: data })
}

// 工作空间重命名
const workspaceModifyName = data => {
  return axios.post('/workspace/modifyName', qs.stringify(data))
}

// 工作空间批量删除
const workspaceDel = data => {
  return axios.post('/workspace/del', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export default { workspaceCreate, workspaceGetAll, workspaceGetPage, workspaceModifyName, workspaceDel };
