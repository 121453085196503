// 素材库store
// 线段上传与编辑窗口数据
const state = {
  addPolylineStyleDialogVisible: {},
  // 监听当前素材库
  currentLibraryData: '',
  // 监听数据来源
  dataSourceId: '',
  // 图片上传与编辑窗口数据
  addImageDialogVisible: {},
  // 图标上传与编辑窗口数据
  addIconDialogVisible: {},
  // 样式上传与编辑窗口数据
  addStyleDialogVisible: {},
  // 多边形上传与编辑窗口数据
  addPlaneDialogVisible: {},
  // 文件夹创建、编辑数据
  fileTextData: {
    id: ''
  },

  // 素材库分类当前分页
  styleCurrentPaged: 1,
  // 当前分类id
  styleCurrentClassifyid: ''
  // 当前类型编号
};

const mutations = {
  setAddPolylineStyleDialogVisible(state, val) {
    state.addPolylineStyleDialogVisible = val;
  },
  setCurrentLibraryData(state, val) {
    state.currentLibraryData = val;
  },
  setDataSourceId(state, val) {
    state.dataSourceId = val;
  },
  setAddImageDialogVisible(state, val) {
    state.addImageDialogVisible = val;
  },
  setAddIconDialogVisible(state, val) {
    state.addIconDialogVisible = val;
  },
  setAddPlaneDialogVisible(state, val) {
    state.addPlaneDialogVisible = val;
  },
  setAddStyleDialogVisible(state, val) {
    state.addStyleDialogVisible = val;
  },
  setFileTextData(state, val) {
    state.fileTextData.id = val;
  },
  setStyleCurrentPaged(state, val) {
    state.styleCurrentPaged = val;
  },
  setStyleCurrentClassifyid(state, val) {
    state.styleCurrentClassifyid = val;
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
