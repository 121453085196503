<template>
  <div
    class="header"
    :style="{ background: [titleColor !== -1 ? '#fff' : ''] }"
  >
    <!-- 头部左侧 -->
    <div class="header-left">
      <router-link to="/"
        ><img class="header-left-logo" src="@/assets/logo.png" alt=""
      /></router-link>
      <div class="nav">
        <router-link
          :class="activeIndex === nav.url ? 'nav-item nav1' : 'nav-item'"
          v-for="nav in navs"
          :key="nav.url"
          :to="nav.url"
          >{{ nav.name }}</router-link
        >
      </div>
    </div>
    <!-- 头部右侧 -->
    <div class="header-right">
      <!-- 通知 -->
      <!-- <list-tooltip content="通知">
        <div class="header-right-icon">
          <Notice class="notice" />
        </div>
      </list-tooltip> -->
      <!-- 关于我们 -->
      <!-- <list-tooltip content="关于我们">
        <div
          :class="
            aboutUsDialogVisible
              ? 'header-right-icon aboutUs'
              : 'header-right-icon'
          "
          @click="handleAboutUs()"
        >
          <i class="icon icon-aboutUs"></i>
        </div>
      </list-tooltip> -->
      <!-- <User v-if="loginData" />
      <Button class="login" @click.native="login()" v-show="!loginData"
        >登录/注册</Button
      > -->
      <Button class="workbench" @click.native="workbenchClick"> 工作台 </Button>
      <!-- <router-link to="/workbench/workspace" target="_blank">
        <Button class="workbench"> 工作台 </Button>
      </router-link> -->
    </div>
  </div>
</template>

<script>
import Button from 'c/Button';
// import Notice from 'c/Notice';
// import User from 'c/workbench/User.vue';
// import ListTooltip from 'c/basic/ListTooltip.vue';
export default {
  name: 'Header',
  components: {
    Button
    // Notice,
    // User
    // ListTooltip
  },
  data() {
    return {
      navs: [
        {
          name: '首页',
          url: '/'
        },
        {
          name: '开发指南',
          url: '/onlineDoc'
        }
        // {
        //   name: '服务升级',
        //   url: '/serviceUpgrade'
        // }
        // {
        //   name: '帮助与支持',
        //   url: '/3'
        // }
      ],
      activeIndex: '',
      titleColor: ''
    };
  },
  created() {
    this.activeIndex = location.hash.slice(1);
  },
  methods: {
    // 重定向
    redirect(url) {
      localStorage.redirectUrl = url;
      // 跳转到登录页
      this.$router.push('/login');
    },
    // 登录按钮
    login() {
      if (this.$route.path === '/login') return;
      localStorage.redirectUrl = window.location.href;
      this.$router.push('/login');
    },
    // 关于我们
    handleAboutUs() {
      this.$store.commit('common/setAboutUsDialogVisible', true);
    },
    // 工作台跳转判断
    workbenchClick() {
      // window.open(window.dataManagerPage, 'dataManagerPage');
      this.$emit('openNoticeDialog');
    }
  },
  computed: {
    loginData: {
      get() {
        return this.$store.state.userData.loginData;
      },
      set(val) {
        this.$store.commit('userData/setLoginData', val);
      }
    },
    aboutUsDialogVisible: {
      get() {
        return this.$store.state.common.aboutUsDialogVisible;
      },
      set(val) {
        this.$store.commit('common/setAboutUsDialogVisible', val);
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.activeIndex = route.path;
        this.titleColor = route.path.indexOf('Detail');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 70px;
  border-bottom: 2px solid rgba(229, 229, 229, 0.3);
  &:hover {
    background-image: url('~a/header-bk.png');
  }
  &-left {
    display: flex;
    align-items: center;
    margin-left: 80px;
    &-logo {
      height: 37px;
      width: 152px;
      background: url('~a/logo.png') no-repeat;
      background-size: contain;
    }
  }
  &-right {
    display: flex;
    align-items: center;
    margin-right: 80px;
    // 通知
    .notice {
      margin: auto;
    }
    &-icon {
      display: flex;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 16px;
      cursor: pointer;
      margin-right: 20px;
      margin-left: -10px;
      .icon {
        margin: auto;
      }
      &:hover {
        background: rgba(80, 124, 255, 0.1);
        .icon-aboutUs {
          margin-top: 7px;
          width: 24px;
          height: 24px;
          background-image: url('~a/workbench/icon-aboutUs.png');
        }
      }
    }
    .aboutUs {
      background: rgba(80, 124, 255, 0.1);
      .icon-aboutUs {
        margin-top: 7px;
        width: 24px;
        height: 24px;
        background-image: url('~a/workbench/icon-aboutUs.png');
      }
    }
  }
}

// 导航
.nav {
  font-size: 16px;
  text-align: center;

  &-item {
    display: inline-block;
    width: 120px;
    line-height: 70px;
    padding: 0 10px;
  }
}
.nav1 {
  position: relative;
  color: #0096ff;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 2px;
    background: #0096ff;
    border-radius: 1px;
  }
}

// 按钮
.login {
  color: #0096ff;
  border: 1px solid #0096ff;
  background-color: #fff;
}
.workbench {
  margin-left: 40px;
  color: #ffffff;
}
</style>
