<template>
  <!-- 认证选择 -->
  <el-dialog
    :visible.sync="selectiveAuthenticateDialogVisible"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    class="selectiveAuthenticate-select"
    top="24vh"
  >
    <!-- 选择认证标题 -->
    <h1 class="selectiveAuthenticate-title">请选择认证方式</h1>
    <p class="selectiveAuthenticate-text">认证后即可使用服务发布功能</p>
    <div class="selectiveAuthenticate-select-box">
      <div class="selectiveAuthenticate-select-box-rz">
        <img src="@/assets/workbench/grrz.png" />
        <p>个人认证开发者</p>
        <el-button
          class="selectiveAuthenticate-select-box-rz-goBtn"
          @click="goAuthentication('grrz')"
          >前往认证</el-button
        >
      </div>
      <div class="selectiveAuthenticate-select-box-rz">
        <img src="@/assets/workbench/qyrz.png" />
        <p>企业认证开发者</p>
        <el-button
          class="selectiveAuthenticate-select-box-rz-goBtn"
          @click="goAuthentication('qyrz')"
          >前往认证</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'SelectiveAuthenticate',
  data() {
    return {};
  },
  computed: {
    selectiveAuthenticateDialogVisible: {
      get() {
        return this.$store.state.userData.selectiveAuthenticateDialogVisible;
      },
      set(val) {
        this.$store.commit(
          'userData/setSelectiveAuthenticateDialogVisible',
          val
        );
      }
    }
  },
  methods: {
    // 认证窗口判断
    goAuthentication(val) {
      if (val == 'grrz') {
        this.$store.commit(
          'userData/setPersonalCertificateDialogVisible',
          true
        );
      } else {
        this.$store.commit(
          'userData/setEnterpriseCertificationDialogVisible',
          true
        );
      }
      setTimeout(() => {
        this.selectiveAuthenticateDialogVisible = false;
      }, 100);
    }
  }
};
</script>

<style lang="less" scoped>
.selectiveAuthenticate {
  // 标题
  &-title {
    margin-top: -20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  // 标题说明
  &-text {
    margin-top: 8px;
    text-align: center;
    font-size: 16px;
    color: #999999;
  }
  // 选择认证窗口
  &-select {
    /deep/.el-dialog {
      width: 586px;
      height: 475px;
      &__header {
        border: none;
      }
    }
    // 认证选择
    &-box {
      background-size: contain;
      display: flex;
      justify-content: space-between;
      margin-top: 44px;
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 40px;
      &-rz {
        cursor: pointer;
        width: 206px;
        height: 291px;
        border: 1px solid #def1ff;
        border-radius: 4px;
        img {
          width: 75px;
          margin: 27px 65px 40px;
        }
        p {
          font-size: 18px;
          text-align: center;
          font-weight: bold;
          color: #333333;
        }
        // 前往认证按钮
        &-goBtn {
          margin: 44px 38px 0;
          width: 130px;
          height: 36px;
          font-size: 14px;
          border-radius: 8px;
          border: 1px solid #0096ff;
          background: #ffffff;
          color: #0096ff;
        }
        &:hover {
          box-shadow: 0px 0px 15px 8px rgba(1, 134, 251, 0.1);
          .selectiveAuthenticate-select-box-rz-goBtn {
            border: 1px solid #0096ff;
            background: #0096ff;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
